class InputMask {    
    init(form) {
        const self = this;        
        self.form = form;
        const masks = self.form.querySelectorAll("[mask]"); 
        [...masks].forEach(mask => {            
            const key = mask.getAttribute("mask");
            self[key](mask);
        });
        // self.form.addEventListener("click", self.handleFormClick.bind(self));
        // const teste = document.querySelectorAll("[aria-mask^='cep']");       
        // teste[0].value = "28300000";
    }

    number(el){
        el.addEventListener("keyup", function(e) {
            const code = e.keyCode ? e.keyCode : e.which;
            if(code === 8) return;  
            this.value = this.value.replace(/\D+/g, ""); 
            return this.value;
        });
        return;
    }

    captalize(el) {
        function run() {
            let texto = el.value.toLowerCase().replace(/(?:^|\s)\S/g, function (capitalize) {
                return capitalize.toUpperCase();
            });
            //preposição digitada
            var PreposM = ["Da", "Do", "Das", "Dos", "A", "E", "De", "DE"];
            //preposição substituta
            var prepos = ["da", "do", "das", "dos", "a", "e", "de", "de"];
        
            for (var i = PreposM.length - 1; i >= 0; i--) {
                texto = texto.replace(RegExp("\\b" + PreposM[i].replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&") + "\\b", "g"), prepos[i]);
            }
        
            el.value = texto;
        }
        run();
        el.addEventListener("focusout", function() {                            
            run();
        });
        return;     
    }

    updateMoney(value){
        let v = value;
        v = v.replace(/\D/g, "");
        v = v.replace(/(\d{1})(\d{8})$/, "$1.$2"); // coloca ponto antes dos ultimos 10 digitos
        v = v.replace(/(\d{2})(\d{7})$/, "$1.$2"); // coloca ponto antes dos ultimos 10 digitos
        v = v.replace(/(\d{1})(\d{5})$/, "$1.$2"); // coloca ponto antes dos ultimos 7 digitos
        v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2"); // coloca virgula antes dos ultimos 4 digitos        
        return v;
    }

    setMoney(value, cifrao=false) {
        let v = value;
        v = v.replace(/\D/g, "");
        v = v.replace(/(\d{1})(\d{8})$/, "$1.$2"); // coloca ponto antes dos ultimos 10 digitos
        v = v.replace(/(\d{2})(\d{7})$/, "$1.$2"); // coloca ponto antes dos ultimos 10 digitos
        v = v.replace(/(\d{1})(\d{5})$/, "$1.$2"); // coloca ponto antes dos ultimos 7 digitos
        v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2"); // coloca virgula antes dos ultimos 4 digitos
        if(cifrao) return `R$ ${v}`;
        return v; 
    }

    money(el, cifrao=false) {
        function format() {
            let v = el.value;
            v = v.replace(/\D/g, "");
            v = v.replace(/(\d{1})(\d{8})$/, "$1.$2"); // coloca ponto antes dos ultimos 10 digitos
            v = v.replace(/(\d{2})(\d{7})$/, "$1.$2"); // coloca ponto antes dos ultimos 10 digitos
            v = v.replace(/(\d{1})(\d{5})$/, "$1.$2"); // coloca ponto antes dos ultimos 7 digitos
            v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2"); // coloca virgula antes dos ultimos 4 digitos
            cifrao ? el.value = `R$ ${v}` : el.value = v;
            // el.value = v;
            return;
        }
        format();

        el.addEventListener("focus", function() {                            
            if(el.value === "R$ 0,00") {
                cifrao ? el.value = "R$ " :  el.value = "";
            }
        });

        el.addEventListener("focusout", function() { 
            if(el.value === "R$ ") {
                cifrao ? el.value = "R$ 0,00" :  el.value = "0,00";
            }                                    
            format()
        });
        el.addEventListener("keyup", function(e) {     
            format()
        });
        return;
    }

    cel(el) {
        el.setAttribute("maxlength", 15);
        el.addEventListener("keyup", function(e) {  
            const code = e.keyCode ? e.keyCode : e.which;
            if(code === 8) return;  
            this.value = this.value.replace(/\D+/g, "");                          
            let v = String(el.value || "");
            v = v.replace(/(\d{2})(\d{5})/, "$1$2-");
            v = v.replace(/(\d{2})/, "($1) ");
            el.value = v; 
            return; 
        });
        return;
    }

    tel(el) {
        el.setAttribute("maxlength", 14);
        el.addEventListener("keyup", function(e) {
            const code = e.keyCode ? e.keyCode : e.which;
            if(code === 8) return;  
            this.value = this.value.replace(/\D+/g, "");
            let v = String(el.value || "");
            v = v.replace(/(\d{2})(\d{4})/, "$1$2-");
            v = v.replace(/(\d{2})/, "($1) "); 
            el.value = v; 
        });
        return;
    }

    updateCep(value){
        let v = value;
        v = v.replace(/\D/g, "");
        v = v.replace(/(\d{5})(\d{3})/, "$1$2");
        v = v.replace(/(\d{5})/, "$1-");       
        return v;
    }
    cep(el) {
        el.setAttribute("maxlength", 9);
        el.addEventListener("keyup", function(e) {    
            const code = e.keyCode ? e.keyCode : e.which;
            if(code === 8) return;    
            this.value = this.value.replace(/\D+/g, "");                    
            let v = String(el.value || "");
            v = v.replace(/\D/g, "");        
            v = v.replace(/(\d{5})(\d{3})/, "$1$2");
            v = v.replace(/(\d{5})/, "$1-");
            this.value = v;
            return;  
        });
        return;
    }

    timeSec(el) {       
        el.setAttribute("maxlength", 8);

        function format() {
            let v = el.value;
            v = v.replace(/\D/g, "");
            v = v.replace(/(\d{2})(\d{2})(\d{2})/, "$1$2$3");
            v = v.replace(/(\d{2})(\d{2})/, "$1$2:");
            v = v.replace(/(\d{2})/, "$1:");
            el.value = v;
            return;
        }

        format();
        // el.addEventListener("focusout", function() {                            
        //     format()
        // });
        el.addEventListener("keyup", function(e) {    
            const code = e.keyCode ? e.keyCode : e.which;
            // console.log(code);
            if(code === 8) return;    
            format()
        });
        return;
    }

    data(el){
        el.setAttribute("maxlength", 10);
        el.addEventListener("keyup", function(e) {    
            const code = e.keyCode ? e.keyCode : e.which;            
            if(code === 8) return; 

            let v = String(el.value || "");             
            if(v.length === 9) return false;
            v = v.replace(/\//g, "");
            v = v.replace(/(\d{2})(\d{2})(\d{5})/, "$1$2$2");
            v = v.replace(/(\d{2})(\d{2})/, "$1$2/");
            v = v.replace(/(\d{2})/, "$1/");
            this.value = v;
            return;  
        });
        return;
    }

    rg(el) {
        el.setAttribute("maxlength", 12);
        el.addEventListener("focusout", function() {                            
            el.value = el.value.replace( /(\d{2,3})(\d{3})(\d{3})([A-Za-z0-9]{1})$/, "$1.$2.$3-$4"); 
            return el.value; 
        });

        el.addEventListener("keyup", function(e) {     
            const code = e.keyCode ? e.keyCode : e.which;
            if(code === 8) return;                       
            // this.value = this.value.replace(/\D+/g, "");
            // 05.558.800-5 055588005
            let v = this.value;
            v = v.replace(/[^0-9a-zA-Z]/g, ""); 
            v = v.replace(/(\d{2})(\d{3})(\d{3})/, "$1$2$3-");
            v = v.replace(/(\d{2})(\d{3})/, "$1$2.");
            v = v.replace(/(\d{2})/, "$1.");
            this.value = v;
        });
        return;
    }

    cpf(el) {
        el.setAttribute("maxlength", 18);         
        el.addEventListener("focusout", function() {                            
            el.value = el.value.replace(/[^0-9]/g, "");
            if(el.value.length === 11) {
                el.value = el.value.replace( /(\d{3})(\d{3})(\d{3})(\d)/ , "$1.$2.$3-$4");
                return false;
            };
            if(el.value.length === 14) {
                el.value = el.value.replace( /(\d{2})(\d{3})(\d{3})(\d{4})(\d)/ , "$1.$2.$3/$4-$5");
                return false;
            };

            if(el.value.trim() !== "") {
                // Msg.fire("Cpf ou Cnpj Inválido!"); 
            }        
        });
        el.addEventListener("keyup", function(e) {     
            const code = e.keyCode ? e.keyCode : e.which;
            if(code === 8) return;                       
            this.value = this.value.replace(/\D+/g, "");

            // 05.570.714/0001-59 999.999.999-99
            let v = this.value;
            v = v.replace(/\D/g, "");
            // cnpj                    
            if(this.value.length > 11) {                       
                v = v.replace(/(\d{2})(\d{3})(\d{3})(\d{4})/, "$1$2$3$4-");
                v = v.replace(/(\d{2})(\d{3})(\d{3})/, "$1$2$3/");
                v = v.replace(/(\d{2})(\d{3})/, "$1$2.");
                v = v.replace(/(\d{2})/, "$1.");
                this.value = v;
                return;
            }
            
            //cpf
            v = v.replace(/(\d{3})(\d{3})(\d{3})/, "$1$2$3-");
            v = v.replace(/(\d{3})(\d{3})/, "$1$2.");
            v = v.replace(/(\d{3})/, "$1.");                 
            this.value = v;
        });
        return;
     }    
     
     reload() {
        const masks = document.querySelectorAll("[mask]");
        [...masks].forEach(el => {
            const key = el.getAttribute("mask");
            this[key](el); 
        });       
    }
}

const startMask = new InputMask();
export default startMask;
// window.Mask = new InputMask();