import React from "react";
import styled, {createGlobalStyle} from "styled-components";
//**Toastfy alert */
// import { toast } from 'react-toastify';

export const GlobalContext = React.createContext({});


export default function GlobalProvider({children}) {     
    const [buttonName, setButtonName] = React.useState("");    
    const [valid, setValid] = React.useState("");
    const [loading, setLoading] = React.useState("start"); // [start, open]   
    const [processtext, setProcessText] = React.useState(false); // [start, open]   
    const [text, setText] = React.useState("Processando."); // [start, open]    

    function startWait(text="Aguarde.") {
        let ponto = ".";
        setText(text);  
        return setInterval(() => {
            setText(`${text}${ponto}`);            
            ponto += ".";
            if(ponto === ".....") ponto = "";
        }, 1000);
    }

    const can = React.useCallback((array = []) => {
        const roleUser = localStorage.getItem("user@roles") || false;

        const escapeRegExpMatch = function(s) {
            return s.replace(/[-\\/\\^$*+?.()|[\]{}]/g, '\\$&');
        };

        function isTrue(match){
            return new RegExp(`\\b${escapeRegExpMatch(match)}\\b`).test(roleUser);
        }

        for(let role of array) {            
            if(isTrue(role)) return true;            
        }

        return false;
    }, []);

    const roleScan = React.useCallback((array = []) => {
        const roleUser = localStorage.getItem("user@roles") || false;

        const escapeRegExpMatch = function(s) {
            return s.replace(/[-\\/\\^$*+?.()|[\]{}]/g, '\\$&');
        };

        function isTrue(match){
            return new RegExp(`\\b${escapeRegExpMatch(match)}\\b`).test(roleUser);
        }

        if(isTrue("superadmin") || isTrue("admin")) return true;

        
        if(array.length > 0) {
            for(let role of array) {                
                if(isTrue(role)) return true;
            }
        }else{
            return false;
        }
    }, []);

    const lastQuery = React.useCallback((str=null) => {
        if(str) {
            localStorage.setItem("@query", JSON.stringify(str));
        }else{
            return JSON.parse(localStorage.getItem("@query")) || null;
        }
    }, []);    

    React.useEffect(()=>{
        let interval;
        if(processtext) {
            interval = startWait("Processando.");
        }else{
            clearInterval(interval);
        }
        
    }, [processtext]);    
        
    return(
        <GlobalContext.Provider value={{buttonName, valid, loading, setButtonName, setValid, setLoading, setProcessText, roleScan, can, lastQuery, startWait}}> 
            <LoadProcess className={processtext ? "open" : ""}>{text}</LoadProcess>        
            {children}
            <GlobalStyle />
        </GlobalContext.Provider>
    )
}

const StyleDiv = ({className, children}) => (
    <div className={className}>        
        {children}
    </div>
)

const LoadProcess = styled(StyleDiv)`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #1500aa;
    color: #FFF;
    z-index: 10000;

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    opacity: 0;
    z-index: -1;

    transition: opacity 0.8s ease-in-out;

    &.open {
        opacity: 1;
        z-index: 1000;
    }
`;

const GlobalStyle = createGlobalStyle`
.green-light {
    background-image: none;
    box-shadow: none;
    color: #91C714 !important;
    fill: #91C714 !important;
    stroke: #91C714 !important;
}
.color-indigo {
  color: #6610f2 !important;
  fill: #6610f2 !important;
  stroke: #6610f2 !important;
}
.color-orange {
  color: #f68710 !important;
  fill: #f68710 !important;
  stroke: #f68710 !important;
}
.color-teal {
  color: #4dc0b5 !important;
  fill: #4dc0b5 !important;
  stroke: #4dc0b5 !important;
}
`