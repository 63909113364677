import React from "react";
import Routes from "./routes/web";
import { BrowserRouter } from 'react-router-dom';

//** Toast alert
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//**AuthContext Provider */
import {AuthProvider} from './contexts/auth';
import GlobalProvider from './contexts/global';
import FormProvider from "./components/FormRef/FormContext";

function App() {
  return (
    <GlobalProvider>
      <FormProvider>
        <AuthProvider>
          <BrowserRouter>
            <Routes/>
            <ToastContainer autoClose={3000}/>
          </BrowserRouter> 
        </AuthProvider>   
      </FormProvider> 
    </GlobalProvider> 
  );
}

export default App;