import React from 'react';
// import { Link } from 'react-router-dom';

//**Components */
import Content from "../../components/Content";
import { Container } from '../../components/Form';
import { Flex, Input, Select } from '../../components/FormRef/Form'; //FilterLabel || Select || ButtonRow
// import MiniSearch from '../../assets/images/buttons/button-mini-search.svg';

// import MiniFilter from '../../assets/images/buttons/button-mini-filter.svg';

// import {FiPrinter, FiEdit} from 'react-icons/fi';
// import { ajax } from '../../api/chequer';
// import Moment from '../../services/Moment';
import styled from 'styled-components';
// import { GlobalContext } from '../../contexts/global';

import { Bar } from '../../components/ApexChart';
import { FormContext } from '../../components/FormRef/FormContext';
import { ajax, serialize } from '../../api/chequer';
import AuthContext from '../../contexts/auth';

export default function Dashboard() {
    const { user } = React.useContext(AuthContext)
    const { fields } = React.useContext(FormContext);
    const [total, setTotal] = React.useState(0);
    const [stage, setStage] = React.useState(0);
    const [todaySale, setTodaySale] = React.useState(null);
    const [dataset, setDataset] = React.useState(null);
    const [titulo, setTitulo] = React.useState("");
    const [company, setCompany] = React.useState("chequer");
    // const {can} = useContext(GlobalContext);

    // const [loading, setLoading] = React.useState(true);    

    // if(loading)return(<div></div>);

    function handleTouchStart(e) {
        const content = e.target.closest(".overflow");

        const startX = e.touches[0].pageX - content.offsetLeft;
        const scrollLeft = content.scrollLeft;

        window.addEventListener('touchmove', handleTouchMove);
        window.addEventListener('touchend', handleTouchEnd);
        content.classList.add("active");

        function handleTouchMove(e) {
            const x = e.touches[0].pageX - content.offsetLeft;
            const walk = (x - startX) * 2; //scroll-fast
            content.scrollLeft = scrollLeft - walk;
        }

        function handleTouchEnd() {
            window.removeEventListener('touchmove', handleTouchEnd);
            window.removeEventListener('touchend', handleTouchEnd);
            content.classList.remove("active");
        }
    }

    function handleMouseDown(e) {
        if (window.innerHeight < 750) {
            return false;
        }
        const content = e.target.closest(".overflow");
        content.classList.toggle("active");

        const startX = e.pageX - content.offsetLeft;
        const scrollLeft = content.scrollLeft;

        window.addEventListener('mousemove', handleMoveMouse);
        window.addEventListener('mouseup', handleMoveMouseUp);

        function handleMoveMouse(e) {
            // const {clientX, clientY, offsetX, offsetY} = e; 
            // console.log(clientX, clientY, offsetX, offsetY);
            const x = e.pageX - content.offsetLeft;
            const walk = (x - startX) * 2; //scroll-fast
            content.scrollLeft = scrollLeft - walk;
        }

        function handleMoveMouseUp(e) {
            window.removeEventListener('mousemove', handleMoveMouse);
            window.removeEventListener('mouseup', handleMoveMouseUp);
            content.classList.toggle("active");
        }
    }

    const startEndDate = React.useCallback(() => {
        setDataset(null);
        setTotal(0);

        const inicio = new Date(fields['year'].value, parseInt(fields['month'].value) - 1, 1);
        const final = new Date(new Date().getFullYear(), parseInt(fields['month'].value), 0);

        const datainicial = `${inicio.getFullYear()}-${(inicio.getMonth() + 1).toString().padStart(2, "0")}-${inicio.getDate().toString().padStart(2, "0")}`;
        const datafinal = `${final.getFullYear()}-${(final.getMonth() + 1).toString().padStart(2, "0")}-${final.getDate().toString().padStart(2, "0")}`;
        const dias = final.getDate().toString().padStart(2, "0");
        return { datainicial, datafinal, dias };
    }, [fields]);

    const getVenda = React.useCallback(async (datainicial, datafinal, year = null, month = null, dias = null) => {
        setTodaySale(null);
        const mth = month <= 9 ? "0" + month : month;
        const day = new Date().toLocaleDateString("en-CA");

        const monthName = ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"];
        if (year && month) setTitulo(`Vendas ${monthName[parseInt(month) - 1]} ${year}`);

        function FloatToReal(val, cifrao = null) {
            if (cifrao) {
                const value = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val).replace("R$", "");
                return value;
            } else {
                const value = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val);
                return value;
            }
        }

        const obj = {
            price: [],
            categories: [],
            total: 0
        }

        const params = [
            { name:'company', value: fields['company'].value},
            { name: 'estab', value: 1 },
            { name: 'dataI', value: `${year}-${mth}-01` },
            { name: 'dataF', value: `${year}-${mth}-${dias}` },
            { name: 'user', value: user.name.split(' ')[0] }
        ]       

        const body = await serialize(params)
        const response = await ajax({ url: `/services/sale`, method: 'post', body, json: true });

        if (response.data === undefined) return false;
        if (response.data.VendasDia === 0) return false;

        const totalDias = dias + 1;

        for (let i = 1; i < totalDias; i++) {
            const cat = i < 10 ? `0${i}` : `${i}`;
            obj.price = [...obj.price, 0.0];
            obj.categories = [...obj.categories, cat];
        }

        response.data.VendasDia.forEach(item => {
            if (day === item.data) setTodaySale(FloatToReal(item.vrVendido, false));
            const index = parseInt(item.data.split("-")[2]);
            obj.price[index - 1] = item.vrVendido;
            obj.total = (parseFloat(obj.total) + parseFloat(item.vrVendido)).toFixed(2);
        });

        setDataset({ categories: obj.categories, series: [{ name: 'Venda', data: obj.price }] });
        setTotal(FloatToReal(obj.total, false));

        // const response = await ajax({ url: `/vendas?page=1&limit=100&datainicial=${datainicial}&datafinal=${datafinal}` });
        // if (response.count === 0) return false;

        // const totalDias = dias + 1;

        // for (let i = 1; i < totalDias; i++) {
        //     const cat = i < 10 ? `0${i}` : `${i}`;
        //     obj.price = [...obj.price, 0.0];
        //     obj.categories = [...obj.categories, cat];
        // }

        // if (response.rows) {
        //     response.rows.forEach(item => {
        //         // console.log(item);
        //         const index = parseInt(item.data_venda.split("-")[2]);
        //         obj.price[index - 1] = item.price;
        //         obj.total = (parseFloat(obj.total) + parseFloat(item.price)).toFixed(2);
        //     });
        // }

        // setDataset({ categories: obj.categories, series: [{ name: 'Venda', data: obj.price }] });
        // setTotal(FloatToReal(obj.total, false));
    }, [user, fields]);

    
//   function handleSearch(e) {
//     e.preventDefault();
//     setDataset();
//     const { datainicial, datafinal, dias } = startEndDate();
//     getVenda(datainicial, datafinal, fields['year'].value, fields['month'].value, parseInt(dias));    
//   }

    // const getSale = useCallback(async () => {
    //     const params = [
    //         { name: 'estab', value: 1 },
    //         { name: 'dataI', value: "2022-09-01" },
    //         { name: 'dataF', value: "2022-09-01" },
    //         { name: 'user', value: user.name.split(' ')[0] }
    //     ]
    //     const body = await serialize(params)
    //     const response = await ajax({ url: `/services/sale`, method: 'post', body, json: true });
    //     console.log(response);
    // }, [user])

    const handleChangeYear = React.useCallback(() => {         
        // setCompany(fields['year'].value);
        const { datainicial, datafinal, dias } = startEndDate();
        getVenda(datainicial, datafinal, fields['year'].value, fields['month'].value, parseInt(dias));
    }, [fields, getVenda, startEndDate]);

    const handleChangeMonth = React.useCallback(() => {         
        // setCompany(fields['month'].value);
        const { datainicial, datafinal, dias } = startEndDate();
        getVenda(datainicial, datafinal, fields['year'].value, fields['month'].value, parseInt(dias));
    }, [fields, getVenda, startEndDate]);

    const handleChangeCompany = React.useCallback(() => {  
        const { datainicial, datafinal, dias } = startEndDate();
        getVenda(datainicial, datafinal, fields['year'].value, fields['month'].value, parseInt(dias));              
    }, [fields, getVenda, startEndDate])

    React.useEffect(() => {        
        if(stage === 0) {
            if (fields['month'] !== undefined) {               
                fields['month'].selectedIndex = new Date().getMonth() + 1;
                const { datainicial, datafinal, dias } = startEndDate();
                getVenda(datainicial, datafinal, fields['year'].value, fields['month'].value, parseInt(dias));
                setStage(1)
            }           
        }       
    }, [stage, fields, startEndDate, getVenda])

    return (
        <Content>
            <Container>
                <Flex className="shadow">
                    <Input col={1} md={2} xs={3} text="Ano.." name="year" inputMode="numeric" defaultValue={new Date().getFullYear()} onMouseLeave={handleChangeYear} />
                    <Select label="Mês.." col={2} md={3} xs={9} name="month" onChange={handleChangeMonth}>
                        <option value="" style={{ display: "none" }}>Selecione...</option>
                        <option value="1">Janeiro</option>
                        <option value="2">Fevereiro</option>
                        <option value="3">Março</option>
                        <option value="4">Abril</option>
                        <option value="5">Maio</option>
                        <option value="6">Junho</option>
                        <option value="7">Julho</option>
                        <option value="8">Agosto</option>
                        <option value="9">Setembro</option>
                        <option value="10">Outubro</option>
                        <option value="11">Novembro</option>
                        <option value="12">Dezembro</option>
                    </Select>
                    <Select label="Empresa.." col={2} md={3} xs={12} name="company" onChange={handleChangeCompany}>
                        <option value={company} style={{ display: "none" }}>{company.toUpperCase()}</option>
                        <option value="chequer">CHEQUER</option>
                        <option value="steakbeer">STEAKBEER</option>                        
                    </Select>
                    {/* <ButtonRow name="btnSearch" src={MiniSearch} alt="mini" onClick={handleSearch} /> */}
                </Flex>
                <FlexTitle>
                    {total !== 0 && (
                        <>
                            <FlexColumn className='_animated-easy-out--bottom' col={12}>
                                <h3>{titulo}</h3>
                            </FlexColumn>

                            {todaySale && (
                                <FlexColumn className='_animated-easy-out--right' col={12}>
                                    <span className='sale'><b>VENDAS DE HOJE: </b>{todaySale}</span>
                                </FlexColumn>
                            )}

                            <FlexColumn className='_animated-easy-out--left' col={12}>
                                <span><b>TOTAL: </b>{total}</span>
                            </FlexColumn>
                        </>
                    )}

                </FlexTitle>
                <FlexBar>
                    {dataset &&
                        <FlexColumn col={12} className='overflow' onMouseDown={handleMouseDown} onTouchStart={handleTouchStart}>
                            <Bar series={dataset.series} categories={dataset.categories} height={400} width={2000} />
                        </FlexColumn>
                    }
                </FlexBar>
            </Container>
        </Content>
    )
}

const FlexTitle = styled.div`
    display: flex;   
    flex-direction: column;   
    margin-top: 1rem;
    padding: 1rem;
    h3 {
        color: #7376a2;
        font-family: unset;
        text-transform: uppercase;
    }   

    span {        
        font-weight: bold;
        color: #7376a2;; //rgba(255,69,96,0.85) rgba(0,227,150,0.85)
        text-decoration: underline;
        position: relative;
        margin-left: 1.5rem;
        b {
            font-weight: 500;
            color: #7376a2;
        }

        &::before{
            content: '';
            position: absolute;
            width: .9rem;
            height: .9rem;
            background-color: #26e7a5;
            top: 50%;
            transform: translateY(-50%);
            left: -1.5rem;
            border-radius: 1rem;
        }

        &.sale::before {
            background-color: #269ffb;
        }
    }
`;

const FlexBar = styled.div`
    display: flex; 
    overflow-block: hidden;
    scrollbar-color: #777 #9f9f9f;
    scrollbar-width: thin;
    flex-wrap: wrap;
    
    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #9f9f9f;
    }

    &::-webkit-scrollbar {
        width: 5px;
        background-color: #9f9f9f;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 0;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #777;
    }   
`;

const FlexColumn = styled.div`
    width: calc(100% / 12 * ${props => props["col"]});    
    user-select: none;
    opacity: 1;
    scrollbar-color: rgba(0,143,251,0.85) #9f9f9f;
    scrollbar-width: thin;
    pointer-events: initial;
    transition: all 0.2s; 
    margin: 2px 0;   

    &.overflow {
        overflow-x: scroll;
        overflow-y: hidden;
    }

    &.active { 
        opacity: 0.4;
        cursor: grabbing !important;
        cursor: -webkit-grabbing !important;    
        /* pointer-events: none;         */
    }   

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #9f9f9f;
    }

    &::-webkit-scrollbar {
        width: 5px;
        background-color: #9f9f9f;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 0;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #777;
    }   

    @media only screen and (min-width: 82em) {
        width: calc(100% / 12 * ${props => props["lg"]});
    }

    @media only screen and (max-width: 75em) {
        width: calc(100% / 12 * ${props => props["md"]});
    }   

    @media only screen and (max-width: 50em) {
        width: 100%;
        width: calc(100% / 12 * ${props => props["xs"]});
    }  
`;