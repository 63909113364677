import React from 'react';
import { ajax } from '../../api/chequer';
import usePaginate from '../../hooks/usePaginate';

import { Table, Thead, Tbody, Paginate, Flex } from '../../components/Table';

import { FiSearch, FiEdit2 } from 'react-icons/fi';
import { HiOutlineCalendar } from 'react-icons/hi';

import { formatDataRegex } from '../../services/utils';

export default function useList(url, limit) {
  const [conditions, setConditions] = React.useState('');
  const [users, setUsers] = React.useState([]);
  const [min, setMin] = React.useState(1);
  const [max, setMax] = React.useState(5);
  const [loading, setLoading] = React.useState(true);
  const { currentPage, cbCurrentPage } = usePaginate();
  const [limitCount, setLimitCount] = React.useState(0);
  // const limitCount = Math.ceil(paginate / limit);

  const handlePaginate = React.useCallback(
    (e) => {
      const el = e.target;
      if (el.matches('.prev')) return currentPage >= 2 ? cbCurrentPage(currentPage - 1) : cbCurrentPage(1);
      if (el.matches('.next'))
        return currentPage <= limitCount - 1 ? cbCurrentPage(currentPage + 1) : cbCurrentPage(limitCount);
      const spanActive = document.querySelector('.content span.active');
      if (spanActive && spanActive.classList.contains('active')) spanActive.classList.remove('active');
      el.classList.add('active');
      cbCurrentPage(parseInt(el.innerText, 10));
    },
    [currentPage, limitCount, cbCurrentPage],
  );

  const handleSelectUser = React.useCallback((e) => {
    const el = e.target;
    const id = el.closest('tr').dataset.id;
    window.location.href = '/users/' + id;
  }, []);

  const memoPaginate = React.useMemo(() => {
    const array = [];
    for (let i = 0; i < limitCount + 1; i++) {
      array.push(i);
    }

    if (currentPage + 1 > max) {
      setMax(max + 4);
      setMin(min + 4);
      return array.splice(min, min).map((item) => (
        <span className={currentPage === item ? 'active' : ''} key={item}>
          {item}
        </span>
      ));
    }

    if (currentPage < min) {
      setMin(min - 4);
      setMax(max - 4);
    }
    return array.splice(min, 5).map((item) => (
      <span className={currentPage === item ? 'active' : ''} key={item}>
        {item}
      </span>
    ));
  }, [limitCount, currentPage, min, max]);

  const TableRender = React.useCallback(() => {
    return (
      <Table>
        <Thead>
          <tr className="">
            <th scope="col">Nome</th>
            <th scope="col">Email</th>
            <th scope="col">Função</th>
            <th scope="col" className="center">
              Férias?
            </th>
            <th scope="col" className="center">
              Status
            </th>
            <th scope="col" className="center">
              Lido
            </th>
            <th scope="col">Action</th>
          </tr>
        </Thead>
        <Tbody>
          {users.rows.map((user) => (
            <tr data-id={user.id} key={user.id} className="" onDoubleClick={handleSelectUser}>
              <td data-label="cliente">
                <span className="mob">Nome</span>
                <p>{user.name}</p>
              </td>
              <td data-label="Assunto">
                <span className="mob">Email</span>
                {user.email}
              </td>
              <td data-label="Assunto">
                <span className="mob">Função</span>
                {user.user_function}
              </td>
              <td data-label="Status" className="center">
                <span className="mob">Férias?</span>
                <span
                  className="badge"
                  style={{ backgroundColor: user.work_status === 'Férias' ? '#f6a935' : '#5cb85c' }}
                >
                  {user.work_status === 'Férias' ? 'Férias' : 'Trabalhando'}
                </span>
              </td>
              <td data-label="Status" className="center">
                <span className="mob">Status</span>
                <span className="badge" style={{ backgroundColor: '#5cb85c' }}>
                  {user.status ? 'Ativo' : 'Desativado'}
                </span>
              </td>
              <td data-label="Cadastrado" className="center">
                <span className="mob">Criado</span>
                <Flex className="center xs-left">
                  <HiOutlineCalendar size={22} />
                  {formatDataRegex(user.createdAt)}
                </Flex>
              </td>
              <td data-label="#" className="nowrap">
                <span className="mob">Action</span>
                <button className="action" style={{ backgroundColor: '#3583f6' }}>
                  <FiSearch color="#fff" size={17} />
                </button>
                <button className="action" style={{ backgroundColor: '#f6a935' }}>
                  <FiEdit2 color="#fff" size={17} />
                </button>
              </td>
            </tr>
          ))}
        </Tbody>
      </Table>
    );
  }, [users, handleSelectUser]);

  const PaginateRender = React.useCallback(() => {
    return (
      limitCount > 2 && (
        <Paginate onClick={handlePaginate}>
          <div className="first active">
            <span className="firstpage">1</span>
            <span className="prev">Prev</span>
          </div>
          <div className="content">{memoPaginate}</div>
          <div className="last active">
            <span className="next">Next</span>
            <span className="lastpage">{limitCount}</span>
          </div>
        </Paginate>
      )
    );
  }, [handlePaginate, memoPaginate, limitCount]);

  React.useEffect(() => {
    async function fetchUsers() {
      const response = await ajax({ url: `${url}/?page=${currentPage}&limit=${limit}${conditions}` });
      setUsers(response);
      setLimitCount(Math.ceil(+response.count / +limit));
      setLoading(false);
    }
    fetchUsers();
  }, [url, limit, currentPage, conditions]);

  return {
    TableRender,
    PaginateRender,
    setConditions,
    loading,
  };
}
