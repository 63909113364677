/* eslint-disable no-unused-vars */
import React from 'react';

export default function useStore() {
  const [name, setName] = React.useState('');
  const [status, setStatus] = React.useState(true);
  const [company, setCompany] = React.useState('');
  const [user_code, setUserCode] = React.useState('');
  const [host_name, setHostName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [planned, setPlanned] = React.useState('08:00:00');
  const [breaks, setBreaks] = React.useState('11:00-13:00');
  const [journey, setJourney] = React.useState('08:30-18:30');
  const [user_function, setUserFunction] = React.useState('');
  const [work_status, setWorkStatus] = React.useState('');
  const [resetPassword, setWorkResetPassword] = React.useState(false);
  const [roles, setRoles] = React.useState([]);
  const [userRoles, setUserRoles] = React.useState([]);

  const submit = [
    { name: 'status', value: status },
    { name: 'user_code', value: user_code },
    { name: 'host_name', value: host_name },
    { name: 'name', value: name },
    { name: 'email', value: email },
    { name: 'company', value: company },
    { name: 'planned', value: planned },
    { name: 'breaks', value: breaks },
    { name: 'journey', value: journey },
    { name: 'user_function', value: user_function },
    { name: 'work_status', value: work_status },
    { name: 'resetPassword', value: resetPassword },
    { name: 'roles', value: roles },
  ];

  const form = {
    name,
    user_code,
    host_name,
    status,
    company,
    email,
    planned,
    breaks,
    journey,
    user_function,
    work_status,
    resetPassword,
    userRoles,
  };

  function reset(params) {
    setName('');
    setStatus(true);
    setCompany('');
    setHostName('');
    setEmail('');
    setPlanned('08:00:00');
    setBreaks('11:00-13:00');
    setJourney('08:30-18:30');
    setUserFunction('');
    setWorkStatus('');
  }

  function setState(data) {
    setName(data.name || '');
    setStatus(data.status || true);
    setCompany(data.company.id || '');
    setUserCode(data.user_code);
    setHostName(data.host_name || '');
    setEmail(data.email || '');
    setPlanned(data.planned || '08:00:00');
    setBreaks(data.breaks || '11:00-13:00');
    setJourney(data.journey || '08:30-18:30');
    setUserFunction(data.user_function || '');
    setWorkStatus(data.work_status || '');
    setUserRoles(data.roles.map((item) => item.id));
  }

  const state = {
    name: setName,
    status: setStatus,
    company: setCompany,
    user_code: setUserCode,
    host_name: setHostName,
    email: setEmail,
    planned: setPlanned,
    breaks: setBreaks,
    journey: setJourney,
    user_function: setUserFunction,
    work_status: setWorkStatus,
    resetPassword: setWorkResetPassword,
    roles: setRoles,
  };

  const options = {
    workStatus: [
      { name: 'Trabalhando', value: 'null' },
      { name: 'Atestado', value: 'atestado' },
      { name: 'Férias', value: 'férias' },
      { name: 'Folga', value: 'folga' },
      { name: 'Licença', value: 'licença' },
      { name: 'Liberado', value: 'liberado' },
    ],
    resetPassword: [
      { name: 'Não', value: false },
      { name: 'Sim', value: true },
    ],
    status: [
      { name: 'Ativo', value: true },
      { name: 'Desligado', value: false },
    ],
    company: [{ name: 'Chequer Megastore', value: 1 }],
  };

  const setCallbackRoles = React.useCallback((data) => {
    setState(data);
  }, []);

  const handleCheckRole = React.useCallback(() => {
    // const array = [];
    const domRoles = document.querySelectorAll('#roles input');
    [...domRoles].forEach((item) => {
      if (userRoles.indexOf(+item.value) !== -1) {
        item.checked = true;
        setRoles((oldValue) => [...oldValue, +item.value]);
        // array.push(+item.value);
      }
    });
    // setRoles(array);
  }, [userRoles]);

  return {
    form,
    submit,
    state,
    options,
    setCallbackRoles,
    handleCheckRole,
    reset,
  };
}
