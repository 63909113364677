import React from "react";



export default function Moment() {  

    const zeroFill = React.useCallback((n)=> {
        return n < 10 ? `0${n}` : `${n}`;
    },[]);

    const requestDate = (date) => {
        return date ? new Date(date) : new Date();
    }

    const fcCurrentDate = ({data, isDate=null, days = 0}) => {
        return  {
            dia: (data.getDate() + (isDate ? 1 : 0) + (days || 0)).toString().padStart(2, "0"),
            mes: (data.getMonth() + 1).toString().padStart(2, "0"), //+1 pois no getMonth Janeiro começa com zero.
            ano: data.getFullYear(),
        };  
    }

    const unDataFormat = React.useCallback( (date) => {
        if(!date) return false;
        const dateSplit = date.split("/");        
        const data = new Date(dateSplit[2], parseInt(dateSplit[1], 10) - 1, dateSplit[0]);
        const currentDate = {
            dia: data.getDate().toString().padStart(2, "0"),
            mes: (data.getMonth() + 1).toString().padStart(2, "0"), //+1 pois no getMonth Janeiro começa com zero.
            ano: data.getFullYear(),
        };  

        return `${currentDate.ano}-${currentDate.mes}-${currentDate.dia}`;        
    }, []);

    const unFormat = React.useCallback( ({date, days}) => {
        if(!date) return false;
        const dateSplit = date.split("/");
        const data = new Date(parseInt(dateSplit[2], 10), parseInt(dateSplit[1], 10) - 1, parseInt(dateSplit[0], 10));
        // const data = requestDate(`${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}`);
        const currentDate = {
            dia: data.getDate().toString().padStart(2, "0"),
            mes: (data.getMonth() + 1).toString().padStart(2, "0"), //+1 pois no getMonth Janeiro começa com zero.
            ano: data.getFullYear(),
        }; 

        return `${currentDate.ano}-${currentDate.mes}-${currentDate.dia}`;
    }, []);

    const todayAddDay = React.useCallback( ({date, days, format}) => { 
        let data = date ? requestDate(date) : requestDate();      
        data = new Date(data.getFullYear(), data.getMonth(), (data.getDate() + (days ? days : 0)));  
        const currentDate = {
            dia: data.getDate().toString().padStart(2, "0"),
            mes: (data.getMonth() + 1).toString().padStart(2, "0"), //+1 pois no getMonth Janeiro começa com zero.
            ano: data.getFullYear(),
        };         

        if(format && format === "dd/mm/yyyy") return `${currentDate.dia}/${currentDate.mes}/${currentDate.ano}`;            
        if(format && format === "yyyy/mm/dd") return `${currentDate.ano}-${currentDate.mes}-${currentDate.dia}`;   
        
        
        
        return `${currentDate.ano}-${currentDate.mes}-${currentDate.dia}T03:00:00.000Z`;
    }, []);
    
    const today = React.useCallback( (date=null) => {
        const data = date ? requestDate(date) : requestDate();        
        const currentDate = {
            dia: (data.getDate() + (date ? 1 : 0)).toString().padStart(2, "0"),
            mes: (data.getMonth() + 1).toString().padStart(2, "0"), //+1 pois no getMonth Janeiro começa com zero.
            ano: data.getFullYear(),
        };                   
        return `${currentDate.ano}-${currentDate.mes}-${currentDate.dia}T03:00:00.000Z`;
    }, []);
    
    const time = React.useCallback( () => {
        const data = requestDate();   
        const currentTime = {
            hour: data.getHours().toString().padStart(2, "0"),
            min: data.getMinutes().toString().padStart(2, "0"),
            sec: data.getSeconds().toString().padStart(2, "0"),
        };
        return `${currentTime.hour}:${currentTime.min}:${currentTime.sec}`;
    
    }, []);

    const getYear = React.useCallback((date=null) => {
        const data = date ? requestDate(date) : requestDate();
        const current = fcCurrentDate({data});
        return current.ano;
    }, []);

    const getMonth = React.useCallback((ret="number") => {
        const monthName = ["janeiro", "fevereiro", "março", "abril", "maio", "junho", "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"];
        const data = requestDate();
        if(ret === "number") return data.getMonth();
        if(ret === "string") return monthName[data.getMonth()];       
    }, []);

    const stringDayOfWeek = React.useCallback((now) => {
        const dayName = ["DOM", "SEG", "TER", "QUA", "QUI", "SEX", "SAB"];
        return dayName[now.getDay()];
    }, []);

    const stringWeekDayMonth = React.useCallback((data) => {
        const now = new Date(data);
        return `${stringDayOfWeek(now)} ${zeroFill(now.getDate())}/${zeroFill(now.getMonth() + 1)}`;        
    }, [stringDayOfWeek, zeroFill]);
    
    const formatToTime = React.useCallback((date)=> {
        const h = zeroFill(date.getHours());
        const mi = zeroFill(date.getMinutes());
        const s = zeroFill(date.getSeconds());
        return `${h}:${mi}:${s}`;
    },[zeroFill]);

    const hoursToSeconds = React.useCallback((str)=> {
        const [hh, mm, ss] = str.split(":").map((nr) => Number(nr) || 0);
        if (String(str).includes("-"))
            return parseInt(`-${Math.abs(hh * 3600 + mm * 60 + ss)}`, 10);
        return Math.abs(hh * 3600 + mm * 60 + ss);
    },[]);

    const converteToTime = React.useCallback((seconds)=> {
        const now = new Date();
        const ds = now.toISOString().split("T")[0];
        const date = new Date(`${ds}T00:00:00-03:00`);

        date.setSeconds(date.getSeconds() + parseInt(Math.abs(seconds), 10));
        if (String(seconds).includes("-")) {
            return `-${formatToTime(date)}`;
        }
        return formatToTime(date);
    },[formatToTime]);

    const weekDay = React.useCallback((date)=> {
        const dataSplit = date.split("-");   
        const ano = parseInt(dataSplit[0], 10); 
        const mes = (parseInt(dataSplit[1], 10) - 1); 
        const dia = parseInt(dataSplit[2], 10); 
        
        const data = new Date(ano, mes, dia);
       
        const dayName = [
            "Domingo",
            "Segunda-feira",
            "Terça-feira",
            "Quarta-feira",
            "Quinta-feira",
            "Sexta-feira",
            "Sábado",
        ];

        return dayName[data.getDay()];        
    }, [])

    const dayOfWeek = React.useCallback((date=null) => {
                const data = date ? requestDate(date) : requestDate();
        return new Promise((resolve) => {
            const newDate = new Date(data);
            const dayName = [
              "Domingo",
              "Segunda-feira",
              "Terça-feira",
              "Quarta-feira",
              "Quinta-feira",
              "Sexta-feira",
              "Sábado",
            ];
            resolve(dayName[newDate.getDay()]);
          });
    }, [])    

    const isnow = React.useCallback((date) => {
        const data = date ? requestDate(date) : requestDate();        
        const currentDate = {
            dia: (data.getDate() + (date ? 1 : 0)).toString().padStart(2, "0"),
            mes: (data.getMonth() + 1).toString().padStart(2, "0"), //+1 pois no getMonth Janeiro começa com zero.
            ano: data.getFullYear(),
        };             
        return `${currentDate.ano}-${currentDate.mes}-${currentDate.dia}T03:00:00.000Z`;
    }, [])

    const todayBR = React.useCallback((date=null) => {
        const data = date ? requestDate(date) : requestDate();        
        const currentDate = {
            dia: (data.getDate() + (date ? 1 : 0)).toString().padStart(2, "0"),
            mes: (data.getMonth() + 1).toString().padStart(2, "0"), //+1 pois no getMonth Janeiro começa com zero.
            ano: data.getFullYear(),
        };                   
        return `${currentDate.dia}/${currentDate.mes}/${currentDate.ano}`;
    }, [])

    return {
        time,
        today,
        todayAddDay,
        unFormat,
        getYear,
        getMonth,
        stringDayOfWeek,
        stringWeekDayMonth,
        hoursToSeconds,
        converteToTime,
        unDataFormat,
        weekDay,
        dayOfWeek,
        isnow,
        todayBR
    }
}