import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

//**Components */
import Content from "../components/Content";
import { Container, Row, Column } from '../components/Form';
import { Table, Thead, Tbody } from '../components/Table';

import { FiPrinter, FiEdit } from 'react-icons/fi';
import { GiInjustice, GiReceiveMoney } from 'react-icons/gi';
import { FcSalesPerformance } from 'react-icons/fc';
import { FaDeezer } from 'react-icons/fa';
import { BsCurrencyExchange } from "react-icons/bs";

import { ajax } from '../api/chequer';
import Moment from '../services/Moment';
import styled from 'styled-components';
import { GlobalContext } from '../contexts/global';

import Abstract from '../assets/images/abstract.jpg';
import Abstract2 from '../assets/images/abstract2.jpg';

export default function Dashboard() {
    const { can } = useContext(GlobalContext);

    const [loading, setLoading] = React.useState(true);
    const [pedidos, setPedidos] = React.useState([]);

    const { today } = Moment();

    const memoCan = React.useMemo(() => can(['pedido', 'superadmin', 'admin']), [can]);
    const memoImposto = React.useMemo(() => can(['imposto', 'superadmin']), [can]);
    const memoVendas = React.useMemo(() => can(['vendas', 'superadmin']), [can]);
    const memoBalanco = React.useMemo(() => can(['balanco', 'superadmin']), [can]);
    const memoBoxAdmin = React.useMemo(() => can(['adminpanel', 'admin', 'superadmin']), [can]);

    React.useEffect(() => {
        async function loadPrdidos() {
            const res = await ajax({ url: "/pedidos?now=" + today() });
            setPedidos(res);
            setLoading(false);
        }
        memoCan ? loadPrdidos() : setLoading(false);
    }, [today, memoCan]);

    if (loading) return (<div></div>);
    // #ffac33 || #ff5733 || #33ff9f || #7433ff || #d733ff || #ff3380

    return (
        <Content>
            <Container>
                {memoBoxAdmin &&
                    <GridMenuRow>
                        {memoImposto &&
                            <GridMenuColumn className='mobile-center' col={3} xs={6}>
                                <GridMenuBox className='bgimage' backGroundImage={Abstract} backGround="#3f6ad8" color="#fff" href='/impostos/grafico'>
                                    <BsCurrencyExchange size={32} />
                                    <span>Gráfico impostos</span>
                                </GridMenuBox>
                            </GridMenuColumn>
                        }

                        {memoVendas &&
                            <GridMenuColumn className='mobile-center' col={3} xs={6}>
                                <GridMenuBox backGround="#ff5733" backGroundImage={Abstract2} color="#fff" href='/vendas/charbar'>
                                    <FaDeezer size={32} />
                                    <span>Gráfico Vendas</span>
                                </GridMenuBox>
                            </GridMenuColumn>
                        }

                        {memoBalanco &&
                            <>
                                <GridMenuColumn className='mobile-center' col={3} xs={6}>
                                    <GridMenuBox backGround="#7433ff" backGroundImage={Abstract} color="#fff" href='/impostos/balance'>
                                        <GiInjustice size={32} />
                                        <span>Balanço Mensal</span>
                                    </GridMenuBox>
                                </GridMenuColumn>
                                <GridMenuColumn className='mobile-center' col={3} xs={6}>
                                    <GridMenuBox backGround="#006600" backGroundImage={Abstract} color="#fff" href='/administrativo/contas-receber'>
                                        <GiReceiveMoney size={32} />
                                        <span>Contas a Receber</span>
                                    </GridMenuBox>
                                </GridMenuColumn>
                                <GridMenuColumn className='mobile-center' col={3} xs={6}>
                                    <GridMenuBox backGround="#660033" backGroundImage={Abstract} color="#fff" href='/administrativo/contas-pagar'>
                                        <FcSalesPerformance size={32} />
                                        <span>Contas a Pagar</span>
                                    </GridMenuBox>
                                </GridMenuColumn>
                            </>

                        }

                        {/* <GridMenuColumn className='mobile-center' col={3} xs={6}>
                        <GridMenuBox backGround="#ffac33" backGroundImage={Abstract1} color="#fff" href='/ponto/calculator'>                           
                            <FaDeezer size={32}/>
                            <span>Gráfico Vendas</span>
                        </GridMenuBox>                         
                    </GridMenuColumn> */}
                    </GridMenuRow>
                }
                <Row>
                    {(memoCan && pedidos.count > 0) && (
                        <Column col={6}>
                            <TableContainer>
                                <Table>
                                    <Thead>
                                        <tr className="">
                                            <th scope="col">Pedido</th>
                                            <th scope="col">Nome</th>
                                            <th scope="col">Horário</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </Thead>
                                    <Tbody>
                                        {pedidos.count > 0 && pedidos.rows.map(item => (
                                            <tr key={item.id}>
                                                <td><span className="mob">Pedido</span>{item['pedido']}</td>
                                                <td><span className="mob">Cliente</span>{item['client_name']}</td>
                                                <td><span className="mob">Horário</span>{item['time']}</td>
                                                <td className="nowrap">
                                                    <span className="mob">Action</span>
                                                    <Link to={`/requests/show/${item['id']}`} className="action" style={{ backgroundColor: '#3583f6' }}><FiPrinter color='#fff' size={17} /></Link>
                                                    <Link to={`/requests/${item['id']}/edit`} className="action" style={{ backgroundColor: '#f6a935' }}><FiEdit color='#fff' size={17} /></Link>
                                                </td>
                                            </tr>
                                        ))}

                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </Column>
                    )}
                </Row>
            </Container>
        </Content >
    )
}


const TableContainer = styled.div`
flex: 1;
overflow: auto;
scrollbar-width: thin;
padding: 0.5rem;
border: 1px solid #ececec;
border-radius: 5px;
@media only screen and (max-width: 37.5em) {
    padding: 0;
    border: none;
}
`;

const GridMenuRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 1rem;
`;

const GridMenuColumn = styled.div`
    margin-right: 1rem;   
    
    width: calc(100% / 12 * ${props => props["col"]});    

    @media only screen and (min-width: 82em) {
        width: calc(100% / 12 * ${props => props["lg"]});
    }

    @media only screen and (max-width: 75em) {
        width: calc(100% / 12 * ${props => props["md"]});
    }   

    @media only screen and (max-width: 50em) {
        width: 100%;
        width: calc(100% / 12 * ${props => props["xs"]});
        
        &.mobile-center {
            margin-right: initial;
            display: flex;
            justify-content: center;
        }
    }  
`;

const GridMenuBox = styled.a`
    position: relative;
    border-radius: 5px;
    box-shadow: 0 1px 10px #436d932e;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    user-select: none;
    color: ${props => props.color};
     
    margin-bottom: 0.5rem;   
    background-color: ${props => props.backGround};
    opacity: 1;
    

    @media only screen and (max-width: 50em) {
        padding: initial;
        width: calc(100% - 0.5rem);
        padding: 1rem 0;
    }  

    svg{
        margin-bottom: 5px;
        transition: all .2s ease;
    }

    span {
        transition: all .2s ease;

        
    }
   
    &::before{      
        content: '';  
        background-image: url(${props => props.backGroundImage});        
        background-size: cover;  
        display: block;
        top: 0;
        left: 0;
        height: 100%;
        position: absolute;
        width: 100%;          
        transition: all .2s ease; 
        transform: scale(0.98);  
        opacity: 0.1;    
    }   

    &:hover {
        cursor: pointer;       
        
        &::before{
            transform: scale(1); 
        }
    }   

    &:hover span,
    &:hover svg {
        transform: scale(1.1);        
    }    
`;
