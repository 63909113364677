/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';

import { FormContext } from './FormContext';

export function Form({ children }) {
  return <form>{children}</form>;
}

function FilterLabelStyled({ className, name, text, type, value, ...rest }) {
  const { registerField } = React.useContext(FormContext);
  const inputRef = React.useRef();

  React.useEffect(() => {
    if (inputRef.current && name) {
      registerField(name, inputRef.current);
    }
  }, [name, registerField]);
  return (
    <label className={className}>
      <input ref={inputRef} name={name} type={!type ? 'text' : type} value={value && value} {...rest} />
      {text}
    </label>
  );
}

export const FilterLabel = styled(FilterLabelStyled)`
  width: calc(100% / 12 * ${(props) => props['col']});

  &.list {
    margin-left: 5px;
    font-size: 0.8rem;
    cursor: pointer;
    input {
      margin-right: 5px;
      cursor: pointer;
    }
  }

  input[type='checkbox'] {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 1rem;
    cursor: pointer;
  }

  @media only screen and (min-width: 75em) {
    width: calc(100% / 12 * ${(props) => props['lg']});
  }

  @media only screen and (max-width: 75em) {
    width: calc(100% / 12 * ${(props) => props['md']});
  }

  @media only screen and (max-width: 50em) {
    width: calc(100% / 12 * ${(props) => props['xs']});

    &.width99 {
      width: 99.7% !important;
    }
  }
`;

function InputStyled({ className, name, type, value, ...rest }) {
  const { registerField } = React.useContext(FormContext);
  const inputRef = React.useRef();

  React.useEffect(() => {
    if (inputRef.current && name) {
      registerField(name, inputRef.current);
    }
  }, [name, registerField]);

  return (
    <div className={className} style={type === 'hidden' ? { display: 'none' } : { display: 'flex' }}>
      <input ref={inputRef} name={name} type={!type ? 'text' : type} value={value && value} {...rest} placeholder=" " autoComplete="off" />
      <span className="control-group__highlight" />
      <span className="control-group__bar" />
      <label className="control-group__label">{rest.text}</label>
    </div>
  );
}

export const GroupList = styled.div`
  display: flex;
  flex-wrap: wrap;
  h2 {
    width: 100%;
    padding: 0 1rem;
    font-size: 1rem;
    color: #7483be;
  }
  label {
    width: auto !important;
  }
`;

//**Input style start */
export const Input = styled(InputStyled)`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.7rem;
  justify-content: flex-end;
  padding: 0 0.5rem;
  overflow: unset;
  user-select: none;

  @media only screen and (max-width: 75em) {
    padding: 0 5px;
  }

  input[type='date'],
  input[type='email'],
  input[type='password'],
  input[type='text'] {
    background-color: unset;
    font-size: 0.9rem;
    padding: 5px 10px 5px 5px;
    display: block;
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-left: none;
    border-radius: none;
    box-shadow: none;
    color: #7376a2;
    font-weight: 500;
    text-transform: uppercase;

    @media only screen and (max-width: 37.5em) {
      font-size: 1rem;
    }

    &:focus {
      outline: none;
      border: none;
    }

    &:disabled {
      cursor: not-allowed;
    }

    &:read-only {
      cursor: not-allowed;
      user-select: none;
    }
  }

  label {
    color: #999;
    font-size: 1rem;
    font-weight: normal;
    position: absolute;
    left: 20px;
    pointer-events: none;
    transform-origin: 0 0;
    transition: 0.4s ease all;
    -moz-transition: 0.4s ease all;
    -webkit-transition: 0.4s ease all;
    bottom: 7px;
    overflow: unset;
  }
  input[type='date']::-ms-input-placeholder,
  input[type='email']::-ms-input-placeholder,
  input[type='password']::-ms-input-placeholder,
  input[type='text']::-ms-input-placeholder {
    color: transparent;
  }
  input[type='date']::placeholder,
  input[type='email']::placeholder,
  input[type='password']::placeholder,
  input[type='text']::placeholder {
    color: transparent;
  }
  input[type='date']:not(:placeholder-shown) ~ label,
  input[type='email']:not(:placeholder-shown) ~ label,
  input[type='password']:not(:placeholder-shown) ~ label,
  input[type='text']:not(:placeholder-shown) ~ label {
    bottom: 1.5rem;
    font-size: 0.7rem;
    color: #5264ae;
    left: 0.8rem;

    @media only screen and (max-width: 37.5em) {
      bottom: 1.6rem;
      left: 0.6rem;
    }
  }

  .control-group__highlight {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    pointer-events: none;
    opacity: 0.15;
  }

  &:last-child .control-group__highlight {
    // height: calc(100% - 1.7rem);
    @media only screen and (max-width: 37.5em) {
      height: 100%;
    }
  }

  /* active state */
  input[type='date']:focus ~ .control-group__highlight,
  input[type='email']:focus ~ .control-group__highlight,
  input[type='password']:focus ~ .control-group__highlight,
  input[type='text']:focus ~ .control-group__highlight {
    -webkit-animation: inputHighlighter 0.6s ease;
    -moz-animation: inputHighlighter 0.6s ease;
    animation: inputHighlighter 0.6s ease;
  }

  input[type='date']:read-only ~ .control-group__highlight,
  input[type='email']:read-only ~ .control-group__highlight,
  input[type='password']:read-only ~ .control-group__highlight,
  input[type='text']:read-only ~ .control-group__highlight {
    animation: none;
  }
  .control-group__bar {
    position: relative;
    display: block;
    width: 100%;
    height: 2px;
    transform: translateY(-2px);
    border-bottom: 1px solid #cecece;
  }

  .control-group__bar:before,
  .control-group__bar:after {
    content: '';
    height: 2px;
    width: 0;
    position: absolute;
    background: #2305b4;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }

  .control-group__bar:before {
    left: 50%;
  }
  .control-group__bar:after {
    right: 50%;
  }

  /* active state */
  input[type='text']:focus ~ .control-group__bar {
    border-bottom: none;
  }

  input[type='text']:focus ~ .control-group__bar:before,
  input[type='text']:focus ~ .control-group__bar:after {
    width: 50%;
  }

  input[type='text']:read-only ~ .control-group__bar:before,
  input[type='text']:read-only ~ .control-group__bar:after {
    background: #cecece;
    top: 1px;
    height: 0.5px;
    width: 50%;
  }

  input:read-only,
  input:read-only ~ label {
    color: #a9aecc !important;
  }

  input[type='hidden'] ~ span,
  input[type='hidden'] ~ label {
    display: none;
  }

  input[type='checkbox'] ~ span,
  input[type='checkbox'] ~ label {
    display: none;
  }

  input[type='checkbox'],
  input[type='hidden'] {
    padding: 0;
    margin: 0;
  }

  &.hidden {
    margin-bottom: initial;
    padding: initial;
    display: none;
  }

  /* input[type=date] {
    font-size: 0.7rem;
} */

  width: calc(100% / 12 * ${(props) => props['col']});

  @media only screen and (min-width: 75em) {
    width: calc(100% / 12 * ${(props) => props['lg']});
  }

  @media only screen and (max-width: 75em) {
    width: calc(100% / 12 * ${(props) => props['md']});
  }

  @media only screen and (max-width: 50em) {
    width: 100%;
    width: calc(100% / 12 * ${(props) => props['xs']});
  }
`;
//**Input style end */

function TextAreaStyled({ children, className, name, value, style, ...rest }) {
  const { registerField } = React.useContext(FormContext);
  const inputRef = React.useRef();

  React.useEffect(() => {
    if (inputRef.current && name) {
      registerField(name, inputRef.current);
    }
  }, [name, registerField]);

  return (
    <div className={className} style={style}>
      <textarea ref={inputRef} name={name} value={value && value} {...rest} placeholder=" " autoComplete="off">
        {children}
      </textarea>
      <span className="control-group__highlight" />
      <span className="control-group__bar" />
      <label className="control-group__label">{rest.text}</label>
    </div>
  );
}

//**TextArea style start */
export const TextArea = styled(TextAreaStyled)`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.7rem;
  justify-content: flex-end;
  padding: 0 0.5rem;
  overflow: unset;

  textarea {
    outline: none;
    -webkit-appearance: none;
    appearance: none;

    background-color: unset;
    font-size: 0.7rem;
    padding: 5px 10px 5px 5px;
    display: block;
    border: 1px solid #cecece;
    border-radius: 5px;
    box-shadow: none;
    color: #7376a2;
    font-weight: 500;
    text-transform: uppercase;
    white-space: pre-wrap;

    @media only screen and (max-width: 50em) {
      font-size: 0.9rem;
    }

    &:focus {
      outline: none;
    }

    &:disabled {
      cursor: not-allowed;
    }

    &:read-only {
      cursor: not-allowed;
      user-select: none;
    }
  }

  label {
    color: #999;
    font-size: 1rem;
    font-weight: normal;
    position: absolute;
    left: 20px;
    pointer-events: none;
    transform-origin: 0 0;
    transition: 0.4s ease all;
    -moz-transition: 0.4s ease all;
    -webkit-transition: 0.4s ease all;
    top: 5px;
    overflow: unset;
  }

  textarea::-ms-input-placeholder {
    color: transparent;
  }

  textarea::placeholder {
    color: transparent;
  }

  textarea:not(:placeholder-shown) ~ label {
    font-size: 0.7rem;
    color: #5264ae;
    left: 0.8rem;
    top: -17px;

    @media only screen and (max-width: 37.5em) {
      bottom: 1.6rem;
      left: 0.6rem;
    }
  }

  .control-group__highlight {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 10px;
    pointer-events: none;
    opacity: 0.15;
  }

  &:last-child .control-group__highlight {
    @media only screen and (max-width: 37.5em) {
      height: 100%;
    }
  }

  textarea:focus ~ .control-group__highlight {
    -webkit-animation: inputHighlighter 0.6s ease;
    -moz-animation: inputHighlighter 0.6s ease;
    animation: inputHighlighter 0.6s ease;
  }

  textarea:read-only ~ .control-group__highlight {
    animation: none;
  }

  &.noResize textarea {
    resize: none;
  }

  width: calc(100% / 12 * ${(props) => props['col']});
  @media only screen and (min-width: 75em) {
    width: calc(100% / 12 * ${(props) => props['lg']});
  }

  @media only screen and (max-width: 75em) {
    width: calc(100% / 12 * ${(props) => props['md']});
  }

  @media only screen and (max-width: 50em) {
    width: calc(100% / 12 * ${(props) => props['xs']});

    &.width99 {
      width: 99.7% !important;
    }
  }
`;
// export const Input = React.memo(InputMemo);

//**Button Row */
function ButtonRowStyled({ children, name, ...props }) {
  const { registerField } = React.useContext(FormContext);
  const inputRef = React.useRef();

  React.useEffect(() => {
    if (inputRef.current && name) {
      registerField(name, inputRef.current);
    }
  }, [name, registerField]);

  return (
    <button ref={inputRef} className={props.className} disabled={props.disabled} onClick={props.onClick}>
      <img src={props.src} alt={props.alt} />
    </button>
  );
}

export const ButtonRow = styled(ButtonRowStyled)`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  max-height: 2.5rem;
  max-width: 2.5rem;
  min-width: 2.5rem;
  margin-right: 5px;
  transform: translateY(-1rem);
  transition: all 0.1s ease-in-out;

  &:active {
    transform: translateY(-1rem) scale(1.7);
  }

  &:disabled {
    transform: translateY(-1rem) scale(1);
    opacity: 0.4;
    cursor: not-allowed;
  }

  @media only screen and (max-width: $media-600) {
    max-height: 3rem;
    max-width: 3rem;
  }
`;

const StyleFlex = ({ className, children }) => <div className={className}>{children}</div>;
export const Flex = styled(StyleFlex)`
  display: flex;
  align-items: center;

  &.center {
    justify-content: center;
  }

  &.wrap {
    flex-wrap: wrap;
  }

  svg {
    margin-right: 5px;
  }

  &.shadow {
    padding: 2rem 0 0 0;
    box-shadow: 0 0 10px #436d932e;
    border-radius: 5px;
  }

  @media only screen and (max-width: 37.5em) {
    flex-wrap: wrap;
    &.xs-left {
      justify-content: flex-start;
    }
  }
`;

function SelectStyled({ children, className, name, type, value, ...rest }) {
  const { registerField } = React.useContext(FormContext);
  const inputRef = React.useRef();

  React.useEffect(() => {
    if (inputRef.current && name) {
      registerField(name, inputRef.current);
    }
  }, [name, registerField]);

  return (
    <div className={className}>
      <select ref={inputRef} {...rest}>
        {children}
      </select>
      <label>{rest.label}</label>
      <span className="bar" />
      <span className="arrow"></span>
    </div>
  );
}

export const Select = styled(SelectStyled)`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.7rem;
  justify-content: flex-end;
  padding: 0 0.5rem;
  overflow: unset;

  select {
    background-color: unset;
    font-size: 0.9rem;
    padding: 5px 10px 5px 0px;
    display: block;
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-left: none;
    border-radius: none;
    box-shadow: none;
    color: #7376a2;
    font-weight: 500;
    text-transform: uppercase;
    background: transparent;

    @media only screen and (max-width: 37.5em) {
      font-size: 1rem;
    }

    &:focus {
      outline: none;
      border: none;
    }
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
  }

  label {
    color: #999;
    font-size: 1rem;
    font-weight: normal;
    position: absolute;
    left: 20px;
    pointer-events: none;
    transform-origin: 0 0;
    transition: 0.4s ease all;
    -moz-transition: 0.4s ease all;
    -webkit-transition: 0.4s ease all;
    bottom: 1rem;
    overflow: unset;

    bottom: 1.5rem;
    font-size: 0.7rem;
    color: #5264ae;
    left: 0.8rem;
  }

  .bar {
    position: relative;
    display: block;
    width: 100%;
    height: 2px;
    transform: translateY(-2px);
    border-bottom: 1px solid #cecece;
  }

  .bar:before,
  .bar:after {
    content: '';
    height: 2px;
    width: 0;
    position: absolute;
    background: #2305b4;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }

  .bar:before {
    left: 50%;
  }
  .bar:after {
    right: 50%;
  }

  /* active state */
  & select:focus ~ .bar {
    border-bottom: none;
  }

  & select:focus ~ .bar:before,
  & select:focus ~ .bar:after {
    width: 50%;
  }

  .arrow {
    position: absolute;
    right: 2.5rem;
    top: 50%;
    display: grid;
    align-items: center;
    pointer-events: none;
    z-index: 12;

    &::before,
    &::after {
      content: '';
      position: absolute;
      background-color: #7376a2;
      width: 0.7rem;
      height: 2px;
      border-radius: 5px;
      transition: all 0.3s ease-in-out;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
      left: 0.4rem;
    }
  }

  & select:focus ~ .arrow::before {
    transform: rotate(-45deg);
  }

  & select:focus ~ .arrow::after {
    transform: rotate(45deg);
  }

  width: calc(100% / 12 * ${(props) => props['col']});

  @media only screen and (min-width: 75em) {
    width: calc(100% / 12 * ${(props) => props['lg']});
  }

  @media only screen and (max-width: 75em) {
    width: calc(100% / 12 * ${(props) => props['md']});
  }

  @media only screen and (max-width: 50em) {
    width: 100%;
    width: calc(100% / 12 * ${(props) => props['xs']});
  }
`;

function MultSelectStyled({ children, className, name, type, value, ...rest }) {
  let values = [];
  const { registerField } = React.useContext(FormContext);
  const inputRef = React.useRef();

  function handleChange(e) {
    e.preventDefault();
    const parent = e.target.offsetParent;
    const customOptions = parent.children[1];
    customOptions.innerHTML = '';
    if (inputRef.current.value.trim() !== '') values = inputRef.current.value.split(',');
    values.push(e.target.value);
    values.map((item) => (customOptions.innerHTML += `<p data-name='${item}'>${item}<span class='remove-option'>x</span></p>`));
    inputRef.current.value = values;
  }

  function handleParentClick(e) {
    e.preventDefault();
    if (e.target.matches('.remove-option')) {
      if (inputRef.current.value.trim() !== '') values = inputRef.current.value.split(',');
      const text = e.target.parentElement.dataset.name;
      e.target.parentElement.remove();
      const index = values.indexOf(text);
      values.splice(index, 1);
      const optionValues = document.querySelector('.select-values p');
      if (!optionValues) document.querySelector('.select-values').innerText = 'SELECIONE...';
      inputRef.current.value = values;
    }
  }

  React.useEffect(() => {
    if (inputRef.current && name) {
      registerField(name, inputRef.current);
    }
    const selectValues = document.querySelector('.select-values');
    selectValues.innerText = 'SELECIONE...';
  }, [name, registerField]);

  return (
    <div className={className} onClick={handleParentClick}>
      <select {...rest} onChange={handleChange}>
        {children}
      </select>
      <div className="select-values" />
      <label>{rest.label}</label>
      <span className="bar" />
      <span className="arrow"></span>
      <input ref={inputRef} />
    </div>
  );
}

export const MultSelect = styled(MultSelectStyled)`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.7rem;
  justify-content: flex-end;
  padding: 0 0.5rem;
  overflow: unset;

  input {
    display: none;
  }

  .select-values {
    position: absolute;
    top: 50%;
    left: 0.8rem;
    transform: translateY(-50%);
    display: flex;
    max-width: 84%;
    overflow: hidden;
    z-index: 4;
    color: #7376a2;
    font-weight: 500;
    font-size: 0.9rem;

    p {
      position: relative;
      padding: 0 1.5rem 0 5px;
      background-color: #7999d7;
      color: #fff;
      border-radius: 2px;
      margin-right: 5px;
      span {
        position: absolute;
        width: 15px;
        top: 2px;
        right: 4px;
        bottom: 2px;
        background-color: #fff;
        color: #979797;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        user-select: none;
        cursor: pointer;
      }
    }
  }

  select {
    position: relative;
    background-color: unset;
    font-size: 0.9rem;
    padding: 5px 10px 5px 0px;
    display: block;
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-left: none;
    border-radius: none;
    box-shadow: none;
    color: #7376a2;
    font-weight: 500;
    text-transform: uppercase;
    background: transparent;
    opacity: 0;
    z-index: 4;

    @media only screen and (max-width: 37.5em) {
      font-size: 1rem;
    }

    &:focus {
      outline: none;
      border: none;
    }
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
  }

  label {
    color: #999;
    font-size: 1rem;
    font-weight: normal;
    position: absolute;
    left: 20px;
    pointer-events: none;
    transform-origin: 0 0;
    transition: 0.4s ease all;
    -moz-transition: 0.4s ease all;
    -webkit-transition: 0.4s ease all;
    bottom: 1rem;
    overflow: unset;

    bottom: 1.5rem;
    font-size: 0.7rem;
    color: #5264ae;
    left: 0.8rem;
  }

  .bar {
    position: relative;
    display: block;
    width: 100%;
    height: 2px;
    transform: translateY(-2px);
    border-bottom: 1px solid #cecece;
  }

  .bar:before,
  .bar:after {
    content: '';
    height: 2px;
    width: 0;
    position: absolute;
    background: #2305b4;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }

  .bar:before {
    left: 50%;
  }
  .bar:after {
    right: 50%;
  }

  /* active state */
  & select:focus ~ .bar {
    border-bottom: none;
  }

  & select:focus ~ .bar:before,
  & select:focus ~ .bar:after {
    width: 50%;
  }

  .arrow {
    position: absolute;
    right: 2.5rem;
    top: 50%;
    display: grid;
    align-items: center;
    pointer-events: none;
    z-index: 12;

    &::before,
    &::after {
      content: '';
      position: absolute;
      background-color: #7376a2;
      width: 0.7rem;
      height: 2px;
      border-radius: 5px;
      transition: all 0.3s ease-in-out;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
      left: 0.4rem;
    }
  }

  & select:focus ~ .arrow::before {
    transform: rotate(-45deg);
  }

  & select:focus ~ .arrow::after {
    transform: rotate(45deg);
  }

  width: calc(100% / 12 * ${(props) => props['col']});

  @media only screen and (min-width: 75em) {
    width: calc(100% / 12 * ${(props) => props['lg']});
  }

  @media only screen and (max-width: 75em) {
    width: calc(100% / 12 * ${(props) => props['md']});
  }

  @media only screen and (max-width: 50em) {
    width: 100%;
    width: calc(100% / 12 * ${(props) => props['xs']});
  }
`;

function SvgLoaderStyled({ className }) {
  const [text, setText] = React.useState('.');

  React.useEffect(() => {
    function ponto() {
      let int = 0;
      setInterval(() => {
        setText((old) => (old += '.'));
        int++;
        if (int === 4) {
          setText('.');
          int = 0;
        }
      }, 1000);
    }

    if (className.match(/open/) && className.match(/open/).length > 0) {
      ponto();
    }
  }, [className]);

  return (
    <div className={className}>
      <svg>
        <path
          fill="#1c3faa;"
          d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z"
        >
          <animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 25 25" to="360 25 25" dur="1.2s" repeatCount="indefinite" />
        </path>
      </svg>
      <span>Enviando{text}</span>
    </div>
  );
}

export const Loader = styled(SvgLoaderStyled)`
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;

  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  z-index: -1;
  opacity: 0;
  transition: all 0.4s ease;

  & svg {
    width: 3rem;
    height: 3rem;
  }

  &.open {
    z-index: 1000;
    opacity: 0.9;
    transform: all;
  }

  span {
    color: #1c3faa;
  }
`;
