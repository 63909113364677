/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
// import '../stylesheets/pages/navbar.css';

import AuthContext from '../contexts/auth';
import { GlobalContext } from '../contexts/global';
import handleToggle from '../services/toggle';

//**Icons */
import { FiUsers, FiPower, FiSettings, FiClipboard, FiFileText } from 'react-icons/fi';
import { BsCurrencyExchange, BsCalendar2Range } from 'react-icons/bs';
import { FaBookReader } from 'react-icons/fa';

import Avatar from '../assets/images/profilePic.jpeg';
import styled from 'styled-components';

export default function Nav() {
  const { roleScan } = React.useContext(GlobalContext);
  const { logout, user } = React.useContext(AuthContext);
  const [activeNavigate, setActiveNavigate] = React.useState('');
  const [activeNavigateLink, setActiveNavigateLink] = React.useState('');

  function Svg(props) {
    var useTag = `<use xlink:href="/images/sprite.svg#${props.icon}" />`;
    return <svg className={props.className} dangerouslySetInnerHTML={{ __html: useTag }} />;
  }

  function handleClick(e) {
    const el = e.target;
    const dataName = el.dataset.name;
    if (el.matches('.item')) {
      if (el.classList.contains('open')) {
        el.classList.remove('open');
        return false;
      }
      const menuItem = document.querySelectorAll('.item');
      [...menuItem].forEach((item) => item.classList.remove('open'));
      setTimeout(() => {
        localStorage.setItem('@navigate', dataName);
        el.classList.add('open');
      }, 200);
    }
  }

  function setNavigate(text) {
    return activeNavigate === text ? 'item open' : 'item';
  }

  function setNavigateLink(text) {
    return activeNavigateLink === text ? 'link active' : 'link';
  }

  React.useEffect(() => {
    function loadNavigation() {
      const navigate = localStorage.getItem('@navigate');
      const navigateLink = localStorage.getItem('@navigateLink');
      setActiveNavigate(navigate);
      setActiveNavigateLink(navigateLink);
    }
    loadNavigation();
  }, []);

  const memoRoleTable = React.useMemo(() => roleScan(['tabela']), [roleScan]);
  const memoRoleUser = React.useMemo(() => roleScan(['usuario']), [roleScan]);
  const memoRolePedido = React.useMemo(() => roleScan(['pedido']), [roleScan]);
  const memoPlanilha = React.useMemo(() => roleScan(['planilha']), [roleScan]);
  const memoImposto = React.useMemo(() => roleScan(['imposto']), [roleScan]);
  const memoBalanco = React.useMemo(() => roleScan(['balanco']), [roleScan]);
  const memoPonto = React.useMemo(() => roleScan(['ponto']), [roleScan]);
  const memoPontoAdmin = React.useMemo(() => roleScan(['pontoadmin']), [roleScan]);
  const memoRoleAdmin = React.useMemo(() => roleScan(), [roleScan]);

  return (
    <Navigate>
      <Dashboard svg={Svg} link={Link} />

      <Figure src={!user.avatar ? Avatar : user.avatar} title={user.name.split(' ')[0]} subTitle={user.user_function} />

      <Divider />
      {memoRoleTable && (
        <NavigateItem arrow={true} className={setNavigate('tabelas')} title="Tabelas" svg={FiClipboard} dataName="tabelas" onClick={handleClick}>
          <NavigationSub>
            <SubItem svg={Svg} dataLink="Primária" onClick={handleToggle} linkClass={setNavigateLink('Primária')} href="/tabelas" linkText="Tabela 15x" />
          </NavigationSub>
          <NavigationSub>
            <SubItem svg={Svg} dataLink="tabela24" onClick={handleToggle} linkClass={setNavigateLink('tabela24')} href="/tabelas/24x" linkText="Tabela 24x" />
          </NavigationSub>
        </NavigateItem>
      )}
      {memoRoleAdmin && (
        <NavigateItem arrow={true} className={setNavigate('vendas')} title="Vendas" svg={BsCurrencyExchange} dataName="vendas" onClick={handleClick}>
          <NavigationSub>
            <SubItem
              svg={Svg}
              dataLink="@vendastore"
              onClick={handleToggle}
              linkClass={setNavigateLink('@vendastore')}
              href="/vendas/register"
              linkText="Registrar"
            />
            <SubItem
              svg={Svg}
              dataLink="@vendachart"
              onClick={handleToggle}
              linkClass={setNavigateLink('@vendachart')}
              href="/vendas/charbar"
              linkText="Gráfico de vendas"
            />
          </NavigationSub>
        </NavigateItem>

      )}
      {memoImposto && (
        <NavigateItem arrow={true} className={setNavigate('imposto')} title="Administrativo" svg={BsCurrencyExchange} dataName="imposto" onClick={handleClick}>
          <NavigationSub>
            <SubItem
              svg={Svg}
              dataLink="@impostoregister"
              onClick={handleToggle}
              linkClass={setNavigateLink('@impostoregister')}
              href="/impostos/register"
              linkText="Registrar imposto"
            />
            <SubItem
              svg={Svg}
              dataLink="@impostographic"
              onClick={handleToggle}
              linkClass={setNavigateLink('@impostographic')}
              href="/impostos/grafico"
              linkText="Relatório gráfico"
            />
            {memoBalanco && (
              <>
                <SubItem
                  svg={Svg}
                  dataLink="@impostobalance"
                  onClick={handleToggle}
                  linkClass={setNavigateLink('@impostobalance')}
                  href="/impostos/balance"
                  linkText="Balanço"
                />
                <SubItem
                  svg={Svg}
                  dataLink="@contaspagar"
                  onClick={handleToggle}
                  linkClass={setNavigateLink('@contaspagar')}
                  href="/administrativo/contas-pagar"
                  linkText="Contas a pagar"
                />
                <SubItem
                  svg={Svg}
                  dataLink="@contasreceber"
                  onClick={handleToggle}
                  linkClass={setNavigateLink('@contasreceber')}
                  href="/administrativo/contas-receber"
                  linkText="Contas a receber"
                />
              </>
            )}
          </NavigationSub>
        </NavigateItem>
      )}
      {memoRolePedido && (
        <NavigateItem arrow={true} className={setNavigate('pedido')} title="Pedido" svg={FiFileText} dataName="pedido" onClick={handleClick}>
          <NavigationSub>
            <SubItem
              svg={Svg}
              dataLink="@requeststore"
              onClick={handleToggle}
              linkClass={setNavigateLink('@requeststore')}
              href="/requests/register"
              linkText="Novo pedido"
            />
            <SubItem
              svg={Svg}
              dataLink="@requestlist"
              onClick={handleToggle}
              linkClass={setNavigateLink('@requestlist')}
              href="/requests"
              linkText="Lista de pedidos"
            />
          </NavigationSub>
        </NavigateItem>
      )}

      {memoRoleUser && (
        <NavigateItem arrow={true} className={setNavigate('usuarios')} title="Usuários" svg={FiUsers} dataName="usuarios" onClick={handleClick}>
          <NavigationSub>
            <SubItem
              svg={Svg}
              dataLink="@userlist"
              onClick={handleToggle}
              linkClass={setNavigateLink('@userlist')}
              href="/users"
              linkText="Lista de usuários"
            />
            <SubItem
              svg={Svg}
              dataLink="@userstore"
              onClick={handleToggle}
              linkClass={setNavigateLink('@userstore')}
              href="/users/register"
              linkText="Cadastrar usuário"
            />
          </NavigationSub>
        </NavigateItem>
      )}

      {memoPlanilha && (
        <NavigateItem arrow={true} className={setNavigate('planilhas')} title="Planilha" svg={BsCalendar2Range} dataName="planilhas" onClick={handleClick}>
          <NavigationSub>
            <SubItem
              svg={Svg}
              dataLink="@planilhaslist"
              onClick={handleToggle}
              linkClass={setNavigateLink('@planilhaslist')}
              href="/planilhas"
              linkText="Relatório"
            />
            <SubItem
              svg={Svg}
              dataLink="@planilhas"
              onClick={handleToggle}
              linkClass={setNavigateLink('@planilhas')}
              href="/planilhas/registro"
              linkText="Cadastrar"
            />
          </NavigationSub>
        </NavigateItem>
      )}

      {memoPonto && (
        <NavigateItem arrow={true} className={setNavigate('pontodesk')} title="Ponto digital" svg={FaBookReader} dataName="pontodesk" onClick={handleClick}>
          <NavigationSub>
            <SubItem
              svg={Svg}
              dataLink="pontodesk"
              onClick={handleToggle}
              linkClass={setNavigateLink('pontodesk')}
              href="/ponto/desktop"
              linkText="Ponto digital"
            />
            {(memoRoleAdmin || memoPontoAdmin) && (
              <>
                <SubItem
                  svg={Svg}
                  dataLink="@adminponto"
                  onClick={handleToggle}
                  linkClass={setNavigateLink('@adminponto')}
                  href="/ponto/list"
                  linkText="Relatório"
                />
                <SubItem
                  svg={Svg}
                  dataLink="@feriados"
                  onClick={handleToggle}
                  linkClass={setNavigateLink('@feriados')}
                  href="/feriados/register"
                  linkText="Registrar feriado"
                />
                <SubItem
                  svg={Svg}
                  dataLink="@feriadoslist"
                  onClick={handleToggle}
                  linkClass={setNavigateLink('@feriadoslist')}
                  href="/feriados/list"
                  linkText="Listar feriados"
                />
              </>
            )}
          </NavigationSub>
        </NavigateItem>
      )}

      <NavigateItem arrow={true} className={setNavigate('configurações')} title="Configurações" svg={FiSettings} dataName="configurações" onClick={handleClick}>
        <NavigationSub>
          <SubItem svg={Svg} dataLink="profile" onClick={handleToggle} linkClass={setNavigateLink('profile')} href="/profile" linkText="Profile" />
          {memoPontoAdmin && (
            <SubItem
              svg={Svg}
              dataLink="Colaboradores"
              onClick={handleToggle}
              linkClass={setNavigateLink('Colaboradores')}
              href="/users"
              linkText="Colaboradores"
            />
          )}
        </NavigationSub>
      </NavigateItem>

      <NavigateItem className={setNavigate('configurações')} title="Sair" svg={FiPower} onClick={logout} />
    </Navigate>
  );
}

const Dashboard = styled(({ svg: Svg, link: Link, ...props }) => (
  <li className={props.className} onClick={props.onClick}>
    <Svg icon="icon-home-dash" />
    <Link to="/">Dashboard</Link>
  </li>
))`
  display: grid;
  grid-template-columns: 1.5rem 1fr 2rem;
  align-items: center;
  grid-column-gap: 8px;
  padding: 8px;
  cursor: pointer;
  background-color: #f1f5f8;
  border-top-left-radius: 3rem;
  border-bottom-left-radius: 3rem;
  color: #1c3faa;

  svg {
    grid-column: 1;
    max-width: 1.6rem;
    max-height: 1.6rem;
    width: 100%;
    fill: #fff;
    pointer-events: none;
    fill: currentColor;
  }

  a,
  a:link,
  a:visited {
    grid-column: 2;
    text-decoration: none;
    font-size: 1.1rem;
    color: currentColor;
    font-weight: 500;
    transform: translateY(2px);

    h6 {
      color: #f1f5f8;
    }

    &:focus {
      outline: none;
    }
  }
`;

//**Divider
const Divider = styled(({ ...props }) => (
  <li className={props.className}>
    <span style={{ margin: 0 }}></span>
  </li>
))`
  display: grid;
  grid-template-columns: 1.5rem 1fr 2rem;
  align-items: center;
  grid-column-gap: 8px;
  // padding: 1rem 2rem 1rem 1rem;
  padding: 8px;
  cursor: pointer;
  span {
    grid-column: 1 / -1;
    height: 2px;
    background-color: #577edf;
    margin-top: 1rem;
  }
`;
//**Avatar item*/
const Figure = styled(({ ...props }) => (
  <li className={props.className}>
    <figure>
      <div>
        <img src={props.src} alt={props.title} />
      </div>
      <div>
        <h3>{props.title}</h3>
        <span>{props.subTitle}</span>
      </div>
    </figure>
  </li>
))`
  figure {
    position: relative;
    padding: 0;
    margin: 0;
    color: #fff;
    height: 5rem;
    display: flex;
    align-items: center;

    img {
      width: 3.5rem;
      height: 3.5rem;
      border-radius: 50%;
      -webkit-filter: drop-shadow(2px 3px 6px #1d2aec);
      filter: drop-shadow(2px 3px 6px #1d2aec);
      object-fit: cover;
      border: 3px solid #3e4aee;
    }

    div:last-child {
      flex: 1;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      h3,
      span {
        margin-left: 5px;
      }

      h3 {
        color: #fff;
        font-size: 1rem;
        width: 100%;
      }

      span {
        font-size: 0.8rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 7rem;
      }
    }
  }
`;

const NavigateStyle = ({ className, children }) => (
  <nav className={className}>
    <ul>{children}</ul>
  </nav>
);

const Navigate = styled(NavigateStyle)`
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: none;
  padding: 1.5rem 0 1rem 1rem;
  border-radius: 8px;
  animation-name: moveInRight;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;

  @media only screen and (max-width: 62.5em) {
    box-shadow: 6px 0px 10px 4px rgba(#185ee0, 0.15);
  }
`;

const NavItemStyle = ({ children, svg: Svg, ...props }) => (
  <li className={props.className} data-name={props.dataName} onClick={props.onClick}>
    <Svg size="22" color="#fff" />
    <h3>{props.title}</h3>
    {props.arrow ? <span className="arrow"></span> : ''}
    {children}
  </li>
);

const NavigateItem = styled(NavItemStyle)`
  display: grid;
  grid-template-columns: 1.5rem 1fr 2rem;
  align-items: center;
  grid-column-gap: 8px;
  // padding: 1rem 2rem 1rem 1rem;
  padding: 8px;
  cursor: pointer;
  color: #fff;

  svg {
    grid-column: 1;
    max-width: 1.6rem;
    max-height: 1.6rem;
    width: 100%;
    pointer-events: none;

    use {
      pointer-events: none;
    }
  }

  h3 {
    pointer-events: none;
    grid-column: 2;
    text-decoration: none;
    font-size: 0.9rem;
    color: #f1f5f8;
    font-weight: 500;

    @media only screen and (max-width: 50em) {
      font-size: 1.1rem;
    }
  }

  &.actived {
    background-color: #1a389f;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    box-shadow: 18px 2px 2px rgba(5, 250, 250, 0.7);
  }

  &.open {
    animation: pureScale 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }

  .arrow {
    grid-column: 3;
    position: relative;
    display: grid;
    align-items: center;
    pointer-events: none;

    &::before,
    &::after {
      content: '';
      position: absolute;
      background-color: #f1f5f8;
      width: 0.7rem;
      height: 2px;
      border-radius: 5px;
      transition: all 0.3s ease-in-out;
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
      left: 0.4rem;
    }
  }

  &.open .arrow::before {
    transform: rotate(-45deg);
  }

  &.open .arrow::after {
    transform: rotate(45deg);
  }
`;

const SubNavigateStyle = ({ className, children }) => <ul className={className}>{children}</ul>;

const NavigationSub = styled(SubNavigateStyle)`
  list-style: none;
  grid-column: 1 / -1;
  background-color: #1a389f;
  border-radius: 6px;
  overflow-y: auto;
  scrollbar-width: none;
  max-height: 0;
  opacity: 0;
  transition: all 0.8s linear;

  ${NavigateItem}.open && {
    max-height: 500px;
    opacity: 1;
    padding: 2px 0;
    margin-top: 5px;
  }
`;

const SubItemStyle = ({ svg: Svg, ...props }) => (
  <li className={props.className} data-link={props.dataLink} onClick={props.onClick}>
    <Svg icon="icon-sub" />
    <Link className={props.linkClass} data-link={props.dataLink} to={props.href}>
      {props.linkText}
    </Link>
  </li>
);

const SubItem = styled(SubItemStyle)`
  display: grid;
  grid-template-columns: 3rem 1fr 2rem;
  align-items: center;
  grid-column-gap: 2px;
  padding: 3px;

  svg {
    grid-column: 1;
    max-width: 1.4rem;
    max-height: 1.4rem;
    width: 100%;
    fill: #fff;
  }

  a {
    grid-column: 2;
    text-decoration: none;
    font-size: 0.8rem;
    color: #fff;

    @media only screen and (max-width: 50em) {
      font-size: 1rem;
    }

    h6 {
      color: #f1f5f8;
    }

    &.active {
      color: #ffd439;
    }
  }
`;
