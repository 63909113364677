import React from 'react';
import '../../stylesheets/pages/login.css';
import './style.css';

import Logo from '../../assets/images/logo-chequer.png';
import Slogan from '../../assets/images/boxslogan.png';

import { toast } from 'react-toastify';

// import {AuthContext} from '../../contexts/auth';
import AuthContext from '../../contexts/auth';
import {GlobalContext} from '../../contexts/global';

export default function Login(){

    const { login } = React.useContext(AuthContext);
    const { buttonName, valid, setButtonName, setValid } = React.useContext(GlobalContext);

    React.useEffect(()=>{
        setButtonName("Entrar")
    },[setButtonName]);

    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");    
    const [required, setRequired] = React.useState(false);

    const state = {
        email: setEmail,
        password: setPassword,
    }

    function stateChange(e){
        const name = e.target.name;
        const value = e.target.value;
        state[name](value);        
    }

    function Svg(props) {
        var useTag = `<use xlink:href="/images/sprite.svg#${props.icon}" />`;
        return <svg className={props.class} dangerouslySetInnerHTML={{__html: useTag }} />;
    }

    function validMessage(msg){
        setValid("not-valid");
        setRequired(true);
        toast.error(msg)
    }

    function handleSubmit(e){         
        e.preventDefault();         
        if(email.trim() === "") return validMessage("Informe seu email..");       
        if(password.trim() === "") return validMessage("Informe sua senha..");   
        setValid("is-valid");        
        setTimeout(() => {
            login(email, password);
        }, 200);         
    }

    //not-valid

    return(
        <>
            <div className="container-login">
                <div className="forn-login">
                    <div className="leftbox">
                        <div className="logobox _animated-easy-out--left">
                            <img src={Logo} alt="Logo empresa"/>
                        </div>
                        <div className="infobox _animated-easy-out--right">
                            <img src={Slogan} alt="Slogan empresa"/>
                        </div>
                    </div>
                    <div className="rightbox">
                        <Svg class="rightbox__logotipo" icon="icon-logotipo"/>
                        <h2 className="_animated-easy-out--left">SEJÁ BEM VINDO</h2>
                        <p className="_animated-easy-out--right">Login</p>
                        <form onSubmit={handleSubmit} autoComplete='on'>                        
                            <div className="formbox _animated-easy-out--top">                            
                                <div className={`input-group ${valid}`}>
                                    <label>Email</label>
                                    <input type="email" name="email" value={email} onChange={stateChange} required={required} autoComplete='on'/>
                                </div>
                                <div className={`input-group ${valid}`}>
                                    <label>Senha</label>
                                    <input type="password" name="password" value={password} onChange={stateChange} required={required}/>
                                </div>           
                            </div>                       
                            <div className="formbutton">
                            <div className="buttonbox _animated-easy-out--left">
                                <button className="buttonbox__button primary hover" type="submit">
                                    {buttonName}
                                    {buttonName === "Entrar" && (
                                        <Svg class="buttonbox__svg right" icon="icon-sign-in"/>
                                    )}                                    
                                </button>
                            </div>
                            </div>
                        </form> 
                    </div>
                </div>
            </div>
        </>
    )
}