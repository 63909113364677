/* eslint-disable react/prop-types */
import React from 'react';
import Content from '../../components/Content';
import styled from 'styled-components';

import { Container, Panel, Row, Column, RowTitle, ButtonAction } from '../../components/Form';
import { FiFileText } from 'react-icons/fi';

import MiniSearch from '../../assets/images/buttons/button-mini-search.svg';
import MiniAdd from '../../assets/images/buttons/button-mini-add.svg';

// import buttonSend from '../../assets/images/buttons/send.button.svg';
import buttonPrint from '../../assets/images/buttons/print.button.svg';
import buttonReset from '../../assets/images/buttons/reset.button.svg';

import { ajax, serialize } from '../../api/chequer';

import { Input, ButtonRow } from '../../components/FormRef/Form';
import { FormContext } from '../../components/FormRef/FormContext';
import { toast } from 'react-toastify';
import Mask from '../../services/mask';

// import { GlobalContext } from "../../contexts/global";

export default function Tabela() {
  const { fields } = React.useContext(FormContext);
  const [loading, setLoading] = React.useState(false);
  const [storageItems, setStorageItems] = React.useState([]);
  const [table, setTable] = React.useState('/tabelas/24/1x4');

  function getPlano() {
    const plano = document.querySelectorAll('#planos input:checked')[0].value;
    return plano;
  }

  //**Calculate */
  function calculator(valor, promocao) {
    const plano = +getPlano();
    const reducePriced = Mask.updateMoney(promocao);
    fields['price'].value = Mask.updateMoney(valor);
    fields['promocao'].value = reducePriced;

    const cPrice = parseFloat(valor);
    const cReducePrice = parseFloat(promocao);
    const desconto = parseFloat(fields['desconto'].value.replace('%', '')) / 100.0;

    //**Determina o valor do preço a vista */
    if (cReducePrice === 0) {
      Mask.updateMoney((cPrice - desconto * cPrice).toFixed(2));
      fields['avista'].value = Mask.updateMoney((cPrice - desconto * cPrice).toFixed(2));
    } else {
      fields['avista'].value = reducePriced;
    }

    //Acrescimo 10X
    const acm10 = cPrice + (cPrice / 100.0) * parseFloat(fields['ac1'].value.replace('%', ''));
    const vl10 = (acm10 / 10).toFixed(2);
    fields['plano1'].value = Mask.updateMoney(vl10);
    // const vl10 = Mask.updateMoney((cPrice / 10).toFixed(2));

    //Acrescimo 15X
    const acm15 = cPrice + (cPrice / 100.0) * parseFloat(fields['ac2'].value.replace('%', ''));
    const vl15 = (acm15 / 15).toFixed(2);
    fields['plano2'].value = Mask.updateMoney(vl15);

    //Acrescimo 24X
    const acm24 = cPrice + (cPrice / 100.0) * parseFloat(fields['ac3'].value.replace('%', ''));
    const vl24 = (acm24 / plano).toFixed(2);
    fields['plano3'].value = Mask.updateMoney(vl24);
  }

  function changeAcrescimo(e) {
    fields['active_taxa'].value = e.target.value;
    const [desc, ac10, ac15, ac24] = e.target.value.split('-');
    fields['desconto'].value = desc;
    fields['ac1'].value = ac10;
    fields['ac2'].value = ac15;
    fields['ac3'].value = ac24;
    calculator(fields['price'].value.replace('.', '').replace(',', '.'), fields['promocao'].value.replace('.', '').replace(',', '.'));
  }

  function changePlano(e) {
    const codigo = fields['codigo'].value.trim();
    const plano = +e.target.value;
    if (plano === 30) {
      fields['plano3'].parentElement.children[3].innerText = '30x de..';
      fields['ac3'].offsetParent.children[3].innerText = '30x';
      if (fields['active_taxa'].value === '10%-0%-12%-22%') {
        fields['ac3'].value = '27%';
      } else if (fields['active_taxa'].value === '10%-0%-16%-29%') {
        fields['ac3'].value = '34%';
      }
    } else {
      fields['plano3'].parentElement.children[3].innerText = '24x de..';
      fields['ac3'].offsetParent.children[3].innerText = '24x';
      if (fields['active_taxa'].value === '10%-0%-12%-22%') {
        fields['ac3'].value = '22%';
      } else if (fields['active_taxa'].value === '10%-0%-16%-29%') {
        fields['ac3'].value = '29%';
      }
    }

    if (codigo === '') return false;
    calculator(fields['price'].value.replace('.', '').replace(',', '.'), fields['promocao'].value.replace('.', '').replace(',', '.'));
  }

  //**Request product on local server
  async function requestProduct() {
    const codigo = fields['codigo'].value;
    const params = [{ name: 'codigo', value: codigo }];
    const body = await serialize(params);
    const response = await ajax({ url: '/services/product', method: 'post', body, json: true });

    if (typeof response.data === 'undefined') {
      // setSearch(false);
      return toast.error('Socket local offline!');
    }

    if (response.data.codigoOut === '') {
      // setSearch(false);
      return toast.error('Produto não cadastrado!');
    }

    fields['produto'].value = response.data.nome;
    fields['padrao'].value = response.data.padrao;

    // fields['price'].value = Mask.updateMoney(response.data.preco);
    // fields['promocao'].value = Mask.updateMoney(response.data.promocao);
    calculator(response.data.preco, response.data.promocao);
  }

  //**Add products on localStorage */
  function addProductStorage() {
    //**Load products on localStorage */
    const storageProducts = JSON.parse(localStorage.getItem('@products24')) || [];

    const productStorage = {
      avista: fields['avista'].value,
      codigo: fields['codigo'].value,
      padrao: fields['padrao'].value,
      produto: fields['produto'].value,
      valor10: fields['plano1'].value,
      valor15: fields['plano2'].value.split(',')[0],
      valor24: fields['plano3'].value.split(',')[0],
      plano: getPlano(),
    };

    //**add product in array */
    storageProducts.push(productStorage);
    setStorageItems(storageProducts);

    //**Save product to localStorage */
    localStorage.setItem('@products24', JSON.stringify(storageProducts));
    // loadProductsStorage();
  }

  //**Remove products on localStorage */
  function removeProductStorage(e) {
    const el = e.target;
    const codigo = el.dataset.value;

    const storageProducts = JSON.parse(localStorage.getItem('@products24')) || [];
    const newStorageProduct = storageProducts.filter((item) => +codigo !== +item.codigo);
    localStorage.setItem('@products24', JSON.stringify(newStorageProduct));
    loadProductsStorage();
  }

  function loadProductsStorage() {
    const products = document.querySelector('.products');
    const storageProducts = JSON.parse(localStorage.getItem('@products24')) || [];

    products.innerHTML = '';
    if (storageProducts.length > 0) {
      setStorageItems(storageProducts);
    } else {
      setStorageItems([]);
    }
    setLoading(false);
  }

  //**Print table selection */
  function handlePrint() {
    return (window.location.href = table);
  }

  function tableChange(e) {
    setTable(e.target.value);
  }

  function handleReset() {
    fields['codigo'].value = '';
    fields['price'].value = '';
    fields['promocao'].value = '';
    fields['avista'].value = '';
    fields['plano1'].value = '';
    fields['plano2'].value = '';
    fields['plano3'].value = '';
    localStorage.removeItem('@products24');
    loadProductsStorage();
  }

  React.useEffect(() => {
    //**Load products on storage */
    loadProductsStorage();
  }, []);

  if (loading) return <div></div>;

  return (
    <Content>
      <Container>
        <Input type="hidden" name="active_taxa" defaultValue="10%-0%-12%-22%" />
        <Panel>
          <RowTitle text="Tabela de 20X">
            <FiFileText size={25} />
          </RowTitle>
          <Row>
            <Input col={4} xs={5} text="Código.." name="codigo" inputMode="numeric" />
            <Column col={4} xs={2}>
              <Row>
                <ButtonRow name="btnSearch" src={MiniSearch} alt="mini" onClick={requestProduct} />
                <ButtonRow name="btnAdd" src={MiniAdd} alt="mini" onClick={addProductStorage} />
              </Row>
            </Column>
          </Row>
          <Row>
            <Input col={6} text="Produto.." name="produto" readOnly={false} />
            <Input col={2} text="Marca.." name="padrao" readOnly={true} />
            <Input col={2} xs={6} text="Preço.." name="price" readOnly={false} />
            <Input col={2} xs={6} text="Promoção.." name="promocao" readOnly={false} />
          </Row>
          <RowTitle text="Planos" />
          <Row id="planos">
            <RadioGroup text="20X" name="change_plano" value="20" onChange={changePlano} defaultChecked={true} /> 
            <RadioGroup text="30X" name="change_plano" value="30" onChange={changePlano} />
          </Row>
          <RowTitle text="Acréscimos" />
          <Row>
            <Input col={1} text="Desconto..." name="desconto" defaultValue="10%" />
            <Input col={1} text="10X" name="ac1" defaultValue="0%" />
            <Input col={1} text="15X" name="ac2" defaultValue="12%" />
            <Input col={1} text="20X" name="ac3" defaultValue="22%" />
            <Input col={2} xs={6} text="A vista.." name="avista" readOnly={false} />
            <Input col={2} xs={6} text="10X de.." name="plano1" readOnly={false} />
            <Input col={2} xs={6} text="15X de.." name="plano2" readOnly={false} />
            <Input col={2} xs={6} text="20X de.." name="plano3" readOnly={false} />
          </Row>
          <RowTitle text="Taxa dos produtos" />
          <Row>
            <RadioGroup text="Eletro" name="taxa" value="10%-0%-16%-29%" onChange={changeAcrescimo} />
            <RadioGroup text="Móveis" name="taxa" value="10%-0%-12%-22%" onChange={changeAcrescimo} defaultChecked={true} />
            <RadioGroup text="Celular" name="taxa" value="0%-0%-14%-28%" onChange={changeAcrescimo} />
            <RadioGroup text="Apple" name="taxa" value="0%-0%-30%-48%" onChange={changeAcrescimo} />
          </Row>
          <RowTitle text="Folha de impressão" />
          <Row>
            <RadioGroup text="1x2" name="table" value="/tabelas/24/1x2" checked={table === '/tabelas/24/1x2' ? true : false} onChange={tableChange} />
            <RadioGroup text="1x4" name="table" value="/tabelas/24/1x4" checked={table === '/tabelas/24/1x4' ? true : false} onChange={tableChange} />
          </Row>

          <Row className="products" style={{ overflow: 'auto', maxHeight: '250px', scrollbarWidth: 'thin' }}></Row>
          <RowTitle text="Produtos para impressão" />
          <Row>
            <Accordion col={12} dataset={storageItems} onRemove={removeProductStorage} />
          </Row>

          <Row>
            <ButtonAction src={buttonPrint} alt="button print" onClick={handlePrint} />
            <ButtonAction src={buttonReset} alt="button print" onClick={handleReset} />
          </Row>
        </Panel>
      </Container>
    </Content>
  );
}

function AccordionStyled({ dataset, className, onRemove, ...rest }) {
  function handleProductClick(e) {
    const el = e.target;

    //**Open accordion */
    if (el.matches('.accordion__header')) {
      e.preventDefault();
      const content = el.nextElementSibling;
      if (parseInt(content.style.height) !== content.scrollHeight) {
        content.style.height = content.scrollHeight + 'px';
      } else {
        content.style.height = '0px';
      }
      return;
    }
  }
  return dataset && dataset.length > 0 ? (
    dataset.map((item) => (
      <div key={item.codigo} className={className} {...rest} onClick={handleProductClick}>
        <button className="accordion__remove" onClick={onRemove} data-value={item.codigo}>
          <img src="/images/buttons/button-mini-remove.svg" alt="button remove" />
        </button>
        <div className="accordion__header">
          <label>PRODUTO: {item.produto}</label>
        </div>
        <div className="accordion__content">
          <p>REF: {item.codigo}</p>
          <p>MARCA: {item.padrao}</p>
          <p>AVISTA: {item.avista}</p>
          <p>10X: R$ {item.valor10}</p>
          <p>15X: R$ {item.valor15},99</p>
          <p>
            {item.plano}X: R$ {item.valor24},99
          </p>
        </div>
      </div>
    ))
  ) : (
    <div />
  );
}

export const Accordion = styled(AccordionStyled)`
  width: calc(100% / 12 * ${(props) => props['col']});
  position: relative;
  width: 100%;
  box-shadow: 1px 1px 8px white;
  margin-top: 2px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 1px 1px 8px rgb(236, 236, 236);
  border-radius: 2px;
  color: #7376a2;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: unset;
  padding: 0 2px;

  .accordion__remove {
    max-width: 2rem;
    max-height: 3rem;
    position: absolute;
    top: 1px;
    right: 18px;
    border: none;
    background-color: transparent;
    cursor: pointer;

    img {
      width: 100%;
      pointer-events: none;
    }
  }

  .accordion__header {
    width: calc(100% - 2rem);
    padding: 8px 2px;
    font-size: 0.9rem;
    color: #5a6bb1;
    background-color: #fdfdfd;

    label {
      pointer-events: none;
      user-select: none;
      text-transform: uppercase;
    }
  }

  .accordion__content {
    background: #fff4e5 !important;
    font-size: 1rem;
    height: 0;
    overflow: hidden;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    padding: 0 1rem;
    background-color: #fdfdfd;
    font-size: 0.7rem;
    color: #5c5c5c;
    border-bottom: 1px solid #eee;

    p {
      padding: 5px 0;
      font-size: inherit;
      color: inherit;
      border-bottom: 1px solid #e3c8c8;
    }
  }
`;

const RadioGroup = ({ ...props }) => (
  <label className={props.className ? `radio-group ${props.className}` : 'radio-group'}>
    <input type="radio" name={props.name} value={props.value} checked={props.checked} defaultChecked={props.defaultChecked} onChange={props.onChange} />
    <span>{props.text}</span>
  </label>
);
