/* eslint-disable react/prop-types */
import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

//**Main route
import Route from './route';

//**Views
import Login from '../views/login/login';
import UserList from '../views/User/List';
import Store from '../views/User/Store';
import Dashboard from '../views/dash';
import Profile from '../views/Profile/Profile';

import PedidoStore from '../views/Pedido/Store';
import PedidoShow from '../views/Pedido/Show';
import PedidoList from '../views/Pedido/List';

import Tabela from '../views/tabela/Tabela';
import Tabela24x from '../views/tabela/Tabela24x';
import Tabela24x1a2 from '../views/tabela/Tabela24x1a2';
import TabelaPrimary24 from '../views/tabela/Primary24';
import TabelaPrimary from '../views/tabela/Primary';
import TabelaSecondary from '../views/tabela/Secondary';
import TabelaThree from '../views/tabela/Three';
import Youtube from '../views/Youtube/Youtube';
import Planilha from '../views/Planilha/Store';
import PlanilhaList from '../views/Planilha/List';
import ListReport from '../views/Planilha/ListReport';
import ImportoStore from '../views/Imposto/Store';
import ImportoGraphic from '../views/Imposto/GraphicReport';
import Balanco from '../views/Imposto/Balanco';
import PontoFeriado from '../views/Ponto/Feriado';
import PontoFeriadoList from '../views/Ponto/FeriadoList';
import PontoDesk from '../views/Ponto/Desk';
import PontoCalc from '../views/Ponto/Calc';
import PontoList from '../views/Ponto/List';
import PontoReport from '../views/Ponto/ReportList';
import PontoReportSetor from '../views/Ponto/ReportListSetor';
import VendaBar from '../views/Venda/VendaBar';
import VendaStore from '../views/Venda/Store';
import ContasPagar from '../views/Admin/ContasPagar';
import ContasReceber from '../views/Admin/ContasReceber';

import Error401 from '../views/error/401';

import AuthContext from '../contexts/auth';

export default function AuthRoutes() {
  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <PrivateRoute exact path="/" conponent={Dashboard} isPrivate />
      <PrivateRoute exact path="/profile" conponent={Profile} isPrivate />
      <PrivateRoute exact path="/tabelas" conponent={Tabela} isPrivate role="tabela" />
      <PrivateRoute exact path="/tabelas/24x" conponent={Tabela24x} isPrivate role="tabela" />
      <PrivateRoute exact path="/tabelas/24/1x2" conponent={Tabela24x1a2} isPrivate role="tabela" />
      <PrivateRoute exact path="/tabelas/24/1x4" conponent={TabelaPrimary24} isPrivate role="tabela" />
      <PrivateRoute exact path="/tabelas/2x4" conponent={TabelaPrimary} isPrivate />
      <PrivateRoute exact path="/tabelas/4x8" conponent={TabelaSecondary} isPrivate />
      <PrivateRoute exact path="/tabelas/2x2" conponent={TabelaThree} isPrivate />
      <PrivateRoute exact path="/error/401" conponent={Error401} isPrivate />
      <PrivateRoute exact path="/users" conponent={UserList} isPrivate role="usuario" />
      <PrivateRoute exact path="/users/register" conponent={Store} isPrivate role="usuario" />
      <PrivateRoute exact path="/users/:id" conponent={Store} isPrivate role="usuario" />
      <PrivateRoute exact path="/requests/register" conponent={PedidoStore} isPrivate role="pedido" />
      <PrivateRoute exact path="/requests/:id/edit" conponent={PedidoStore} isPrivate role="pedido" />
      <PrivateRoute exact path="/requests/show/:id" conponent={PedidoShow} isPrivate role="pedido" />
      <PrivateRoute exact path="/requests" conponent={PedidoList} isPrivate role="pedido" />
      <PrivateRoute exact path="/youtube" conponent={Youtube} isPrivate role="superadmin" />
      <PrivateRoute exact path="/planilhas/registro" conponent={Planilha} isPrivate role="planilha" />
      <PrivateRoute exact path="/planilhas/registro/:id/:planilhaId" conponent={Planilha} isPrivate role="planilha" />
      <PrivateRoute exact path="/planilhas" conponent={PlanilhaList} isPrivate role="planilha" />
      <PrivateRoute exact path="/planilhas/report" conponent={ListReport} isPrivate role="planilha" />
      <PrivateRoute exact path="/impostos/register" conponent={ImportoStore} isPrivate role="imposto" />
      <PrivateRoute exact path="/impostos/grafico" conponent={ImportoGraphic} isPrivate role="imposto" />
      <PrivateRoute exact path="/impostos/balance" conponent={Balanco} isPrivate role="balanco" />
      <PrivateRoute exact path="/feriados/register" conponent={PontoFeriado} isPrivate role="ponto" />
      <PrivateRoute exact path="/feriados/update/:id" conponent={PontoFeriado} isPrivate role="ponto" />
      <PrivateRoute exact path="/feriados/list" conponent={PontoFeriadoList} isPrivate role="ponto" />
      <PrivateRoute exact path="/ponto/desktop" conponent={PontoDesk} isPrivate role="ponto" />
      <PrivateRoute exact path="/ponto/calculator" conponent={PontoCalc} isPrivate role="ponto" />
      <PrivateRoute exact path="/ponto/list" conponent={PontoList} isPrivate role="pontoadmin" />
      <PrivateRoute exact path="/ponto/report" conponent={PontoReport} isPrivate role="pontoadmin" />
      <PrivateRoute exact path="/ponto/report-setor" conponent={PontoReportSetor} isPrivate role="pontoadmin" />
      <PrivateRoute exact path="/vendas/register" conponent={VendaStore} isPrivate role="vendas" />
      <PrivateRoute exact path="/vendas/charbar" conponent={VendaBar} isPrivate role="vendas" />
      <PrivateRoute exact path="/administrativo/contas-pagar" conponent={ContasPagar} isPrivate role="balanco" />
      <PrivateRoute exact path="/administrativo/contas-receber" conponent={ContasReceber} isPrivate role="balanco" />
    </Switch>
  );
}

function PrivateRoute({ conponent: Component, ...rest }) {
  const { signed } = React.useContext(AuthContext);
  return signed ? <Route {...rest} component={Component} /> : <Redirect to="/login" />;
}
