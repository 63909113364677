import React from 'react';
import Content from '../../components/Content';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Container, Panel, Row, GroupInput, GroupSelect, RowTitle, ButtonAction } from '../../components/Form';

// import MiniSearch from '../../assets/images/buttons/button-mini-search.svg';
// import MiniAdd from '../../assets/images/buttons/button-mini-add.svg';
import buttonSend from '../../assets/images/buttons/button-send.svg';
import buttonReset from '../../assets/images/buttons/button-reset.svg';
import { FiUsers } from 'react-icons/fi';

import { ajax, serialize } from '../../api/chequer';
import useStore from '../../hooks/useStore';
import { toast } from 'react-toastify';
import { GlobalContext } from '../../contexts/global';
// import {ajax, serialize} from '../../api/chequer';
// import Mask from '../../services/mask';
// import { toast } from 'react-toastify';

export default function Profile() {
  return (
    <Content>
      <ProdileContent />
    </Content>
  );
}

function ProdileContent() {
  const { can } = React.useContext(GlobalContext);

  const { id } = useParams();
  const [roles, setRoles] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [stateButton, setStateButton] = React.useState(false);
  const { state, form, options, submit, setCallbackRoles, handleCheckRole, reset } = useStore();

  function handleChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    state[name](value);
  }

  function handleSelectChange(e) {
    const name = e.target.name;
    const value = e.target.value;
    state[name](value);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setStateButton(true);

    const body = await serialize(submit);
    if (id) {
      const response = await ajax({ url: '/users/' + id, method: 'put', body, json: true });
      if (response.error) return toast.error(response.error);
      if (response.success && response.message) toast.success(response.message);
    } else {
      const response = await ajax({ url: '/users', method: 'post', body, json: true });
      if (response.message && !response.success) toast.warning(response.message);
      if (response.message && response.success) toast.success(response.message);
    }

    setTimeout(() => {
      setStateButton(false);
    }, 1000);
  }

  function handleReset(e) {
    e.preventDefault();
    setStateButton(true);
    reset();
    setTimeout(() => {
      setStateButton(false);
    }, 1000);
  }

  function handleChangeRoles() {
    const array = [];
    const domRoles = document.querySelectorAll('#roles input[type=checkbox]:checked');
    [...domRoles].map((item) => array.push(item.value));
    state.roles(array);
  }

  React.useEffect(() => {
    async function loadUser() {
      const response = await ajax({ url: '/users/' + id });
      setCallbackRoles(response);
      setLoading(false);
    }
    if (id) {
      loadUser();
    } else {
      setLoading(false);
    }
  }, [id, setCallbackRoles]);

  React.useEffect(() => {
    async function loadRoles() {
      const res = await ajax({ url: '/roles' });
      if (res.rows) setRoles(res.rows);
    }
    loadRoles();
    if (!loading) setTimeout(handleCheckRole, 100);
  }, [loading, handleCheckRole]);

  const memoCan = React.useMemo(() => can(['superadmin']), [can]);

  return (
    <Container>
      <form id="formUser" onSubmit={handleSubmit}>
        <RowTitle text="USUÁRIOS">
          <FiUsers size={25} />
        </RowTitle>
        <Panel>
          <GroupInput
            required={true}
            text="Código.."
            type="text"
            col={4}
            name="user_code"
            value={form.user_code}
            onChange={handleChange}
            readOnly={id ? true : false}
          />
          <GroupInput
            text="Dispositivo.."
            type="text"
            col={6}
            name="host_name"
            value={form.host_name}
            onChange={handleChange}
          />
          <GroupInput
            required={true}
            text="Nome.."
            type="text"
            col={6}
            name="name"
            value={form.name}
            onChange={handleChange}
          />
          <GroupInput
            required={true}
            text="Email.."
            type="text"
            col={6}
            name="email"
            value={form.email}
            onChange={handleChange}
          />
          <GroupSelect
            required={true}
            options={options.company}
            label="Empresa.."
            col={4}
            name="company"
            value={form.company}
            onChange={handleChange}
          />
          <GroupSelect
            required={true}
            options={options.status}
            label="Situação.."
            col={2}
            name="status"
            value={form.status}
            onChange={handleChange}
          />
          <GroupInput
            required={true}
            text="Planejado.."
            type="text"
            col={2}
            name="planned"
            value={form.planned}
            onChange={handleChange}
          />
          <GroupInput
            required={true}
            text="Intervalos.."
            type="text"
            col={2}
            name="breaks"
            value={form.breaks}
            onChange={handleChange}
          />
          <GroupInput
            required={true}
            text="Jornada.."
            type="text"
            col={2}
            name="journey"
            value={form.journey}
            onChange={handleChange}
          />
          <GroupInput
            required={true}
            text="Função.."
            type="text"
            col={4}
            name="user_function"
            value={form.user_function}
            onChange={handleChange}
          />
          <GroupSelect
            options={options.workStatus}
            label="Atestado, ferias, folga.."
            col={4}
            name="work_status"
            value={form.work_status ? form.work_status.toLowerCase() : ''}
            onChange={handleSelectChange}
          />
          <GroupSelect
            options={options.resetPassword}
            label="Resetar senha.."
            col={4}
            name="resetPassword"
            value={form.resetPassword}
            onChange={handleChange}
          />
          <RowTitle text="Roles" />
          <Row id="roles">
            {/* {memoCan && <span>can sim</span>} */}
            {roles &&
              roles.map((role) =>
                memoCan ? (
                  <GroupCheckbox
                    key={role.id}
                    md={3}
                    xs={6}
                    lg={2}
                    value={role.id}
                    text={role.guard_name}
                    onChange={handleChangeRoles}
                  />
                ) : (
                  role.name !== 'super admin' &&
                  role.name !== 'admin' && (
                    <GroupCheckbox
                      key={role.id}
                      md={3}
                      xs={6}
                      lg={2}
                      value={role.id}
                      text={role.guard_name}
                      onChange={handleChangeRoles}
                    />
                  )
                ),
              )}
          </Row>

          <Row>
            <ButtonAction type="submit" src={buttonSend} alt="mini" disabled={stateButton} />
            <ButtonAction src={buttonReset} alt="mini" disabled={stateButton} onClick={handleReset} />
          </Row>
        </Panel>
        {/* <Group col={4} md={6} xs={9} text="Codigo.." type="text" name="user_code" value={usercode} onChange={handleChange}/> */}

        {/* <Column col={4} xs={3}>
                    <Row>
                        <ButtonRow src={MiniSearch} alt="mini"/>
                        <ButtonRow src={MiniAdd} alt="mini"/>
                    </Row>
                </Column> */}
      </form>
    </Container>
  );
}

const GroupCheckbox = styled(({ ...props }) => (
  <label className={props.className}>
    <input type="checkbox" name={props.name} value={props.value} checked={props.checked} onChange={props.onChange} />
    <span>{props.text}</span>
  </label>
))`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0 0.5rem;
  overflow: unset;
  cursor: pointer;
  white-space: nowrap;
  user-select: none;

  &:last-child {
    /* margin-bottom: 1.7rem; */
  }

  input[type='checkbox'] {
    min-width: 1.3rem;
    min-height: 1.3rem;
    cursor: pointer;
    transition: all 0.4 ease-in-out;
  }

  span {
    background-color: unset;
    font-size: 0.9rem;
    padding: 5px 10px 5px 5px;
    color: #7376a2;
    font-weight: 500;
    text-transform: uppercase;
    margin-left: 5px;
  }

  width: calc(100% / 12 * ${(props) => props['col']});

  @media only screen and (min-width: 75em) {
    width: calc(100% / 12 * ${(props) => props['lg']});
  }

  @media only screen and (max-width: 75em) {
    width: calc(100% / 12 * ${(props) => props['md']});
  }

  @media only screen and (max-width: 50em) {
    width: 100%;
    width: calc(100% / 12 * ${(props) => props['xs']});
  }
`;
